.wu-realisation {
  padding-top: 55px;
  padding-bottom: 50px;
  animation-fill-mode: backwards !important;
  .grid {
    margin-left: -15px;
    margin-right: -15px;
    .grid-sizer,
    .grid-item {
      padding-left: 15px;
      padding-right: 15px;
      width: 25%;
      @include media-breakpoint-down(md) {
        width: 33.33333%;
      }
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
    .grid-item {
      margin-bottom: 30px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .more {
    text-align: center;
    .btn {
      margin-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

#galleryModal {
  .modal-dialog {
    margin: 50px auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1200px;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .modal-body {
    padding: 0;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;

      > * {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .desc {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    padding: 10px 15px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 0.75rem;
    line-height: 1.25;

    @include media-breakpoint-down(md) {
      order: -1;
      position: relative;
      bottom: auto;
      left: auto;
      padding-right: 50px;
    }

    h3 {
      margin: 0;
      color: inherit;
      font-size: 0.875rem;
      line-height: 1.45;
    }
  }

  .close {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(../../../default/files/icons/icon-close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 25px;
    @include transition(all 0.35s ease-out);

    &:hover {
      background-color: rgba(0, 0, 0, 1);
    }
  }

  .item-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    width: 100%;
    height: calc(100vh - 100px);
    opacity: 0;
    visibility: hidden;
    @include transition(opacity 0.35s ease-out);

    @include media-breakpoint-down(md) {
      height: auto;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .owl-nav {
    display: flex;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    margin: 0;

    [class*="owl-"] {
      display: block;
      margin: 0;
      border: 0;
      border-radius: 0;
      padding: 0;
      width: 50px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.5) !important;
      background-image: url(../../../default/files/icons/icon-arrow.svg) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: auto 12px !important;
      outline: 0 !important;
      @include transition(all 0.3s ease-out);

      span {
        display: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 1) !important;
      }
    }
    .owl-prev {
      -ms-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}
