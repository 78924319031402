.wu-gallery {
	padding-top: 60px;
	padding-bottom: 35px;
	.container-fluid {
		padding: 0;
	}
	h2 {
		margin-bottom: 15px;
		text-align: center;
	}
	.item-carousel {
		padding: 30px 15px 50px;
		@include transition(all .3s ease-out);
		.img-wrapper {
			background-color: #000;
			img {
				width: auto;
				height: 500px;
				opacity: .4 !important;
				@include transition(all .3s ease-out);
				@include media-breakpoint-down(md) {
					width: 100%;
					height: auto;
					opacity: 1 !important;
				}
			}
		}
	}
	.owl-item.center {
		.item-carousel {
			-ms-transform: translateY(-10px);
			-webkit-transform: translateY(-10px);
			transform: translateY(-10px);
			.img-wrapper {
				img {
					opacity: 1 !important;
					box-shadow: 0 20px 40px 0 rgba(0,0,0,0.30);
				}
			}
		}
	}
	.owl-nav {
	    margin: 0;
	    [class*=owl-] {
		    display: inline-block;
		    position: absolute;
		    z-index: 100;
		    top: 250px;
		    margin: 0;
		    border: 1px solid #fff;
		    border-radius: 50%;
		    padding: 0;
		    width: 50px;
		    height: 50px;
		    background-color: transparent !important;
		    background-image: url(../../../default/files/icons/icon-arrow.svg) !important;
		    background-position: center !important;
		    background-repeat: no-repeat !important;
		    background-size: 7px auto !important;
		    font-size: 0 !important;
		    @include transition(all .3s ease-out);
		    @include media-breakpoint-down(lg) {
				top: auto;
				bottom: -15px;
				background-color: #979797 !important;
		    }
		    &:hover {
			    background-color: #000 !important;
			}
		}
		.owl-prev {
			left: 150px;
			-ms-transform: rotate(-180deg);
			-webkit-transform: rotate(-180deg);
			transform: rotate(-180deg);
			@media (max-width: 1319px) {
				left: 90px;
			}
			@include media-breakpoint-down(md) {
				left: 15px;
			}
		}
		.owl-next {
			right: 150px;
			@media (max-width: 1319px) {
				right: 90px;
			}
			@include media-breakpoint-down(md) {
				right: 15px;
			}
		}
	}
	.owl-dots {
		position: relative;
		z-index: 50;
		margin: -25px 0 0;
		font-size: 0;
	    .owl-dot {
		    display: inline-block;
		    margin: 3px;
		    padding: 4px;
		    span {
			    display: block;
			    margin: 0;
			    border: 1px solid #979797;
			    border-radius: 50%;
		    	width: 6px;
			    height: 6px;
			    background-color: transparent !important;
			    @include transition(all .3s ease-out);
		    }
		    &:hover, &.active {
				span {
					background-color: #000 !important;
					border-color: #000;
				}
		    }
		}
	}
}
