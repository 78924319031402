.shop-cart {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 900;
  top: 90px;
  right: 0;
  border-radius: 4px 0 0 4px;
  background-color: $brand-primary;
  outline: 0 !important;
  text-decoration: none !important;
  @include transition(all 0.5s);

  @include media-breakpoint-up(lg) {
    top: 170px;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 48px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.83 21.61'%3e%3cpath fill='%23fff' d='M17.82,20.71,16.51,6.11a.77.77,0,0,0-.77-.7H13V4.19A4.12,4.12,0,1,0,4.8,4.06V5.4H2.09a.78.78,0,0,0-.77.7L0,20.76a.78.78,0,0,0,.7.84H17.06a.78.78,0,0,0,.77-.78A.49.49,0,0,0,17.82,20.71ZM6.34,4.19a2.58,2.58,0,0,1,5.15,0h0V5.4H6.34ZM1.62,20.06,2.8,7h2V8.33a.77.77,0,0,0,.77.78.78.78,0,0,0,.77-.78h0V7h5.15V8.33a.77.77,0,0,0,.77.78A.79.79,0,0,0,13,8.33h0V7h2l1.17,13.11Z'/%3e%3c/svg%3e");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px 22px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -5px;
      left: -5px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: #000;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
    }
  }

  > span {
    padding: 10px 15px;
    max-width: 300px;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    @include transition(all 0.2s);

    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: 0;
    }
  }
  &.scrolled {
    > span {
      padding: 0;
      max-width: 0;
    }
  }
  &:hover {
    > span {
      padding: 10px 15px;
      max-width: 300px;
    }
  }
}

.item--produit {
  display: block;
  text-decoration: none;

  .img-wrapper {
    position: relative;
    height: 330px;
    background-color: #f0f0f0;

    .promo {
      position: absolute;
      z-index: 0;
      top: 15px;
      left: 15px;
      padding: 5px 10px;
      background-color: $brand-primary;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
      text-transform: uppercase;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      margin-top: 20px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.83 21.61'%3e%3cpath fill='%23000' d='M17.82,20.71,16.51,6.11a.77.77,0,0,0-.77-.7H13V4.19A4.12,4.12,0,1,0,4.8,4.06V5.4H2.09a.78.78,0,0,0-.77.7L0,20.76a.78.78,0,0,0,.7.84H17.06a.78.78,0,0,0,.77-.78A.49.49,0,0,0,17.82,20.71ZM6.34,4.19a2.58,2.58,0,0,1,5.15,0h0V5.4H6.34ZM1.62,20.06,2.8,7h2V8.33a.77.77,0,0,0,.77.78.78.78,0,0,0,.77-.78h0V7h5.15V8.33a.77.77,0,0,0,.77.78A.79.79,0,0,0,13,8.33h0V7h2l1.17,13.11Z'/%3e%3c/svg%3e");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 18px 22px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      object-fit: contain;
    }
  }

  .content {
    padding: 20px 0 0;
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;

    h3 {
      margin: 0 0 10px;
      color: #000;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25;
    }

    .price {
      margin: 0;

      span:not([class*="old"]) {
        color: $brand-primary;
      }

      .old {
        color: #999;
        text-decoration: line-through;
      }

      &.no-promo {
        span:not([class*="old"]) {
          color: #000;
        }
      }
    }
  }

  &:hover {
    .img-wrapper {
      &::after {
        margin-top: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.catalog-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f0f0f0;
  color: #7e7e7e;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.15;

  ol {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style-type: none;

    li {
      display: flex;
      flex-wrap: wrap;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      & + li {
        &::before {
          content: "/";
          padding: 0 5px;
        }
      }
    }
  }
}

.catalog {
  padding-top: 40px;
  padding-bottom: 60px;

  .inner {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 260px 1fr;
    }
  }

  .catalog-filter {
    padding-top: 5px;
    padding-bottom: 5px;

    .filter {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 40px;

      h3 {
        margin: 0 0 40px;
        color: #000;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.35;
      }

      .categories-tree {
        padding-left: 0;
        list-style-type: none;

        > li {
          > a {
            display: block;
            position: relative;
            padding-left: 30px;
            color: #000;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25;
            text-decoration: none;
            transition: all 0.2s ease-in;

            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }

            &::before {
              z-index: 1;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 9px 6px;
            }

            &::after {
              border: 1px solid #e1e1e1;
              background-color: #fff;
            }

            &:hover {
              color: $brand-primary;
            }

            &.active {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6'%3e%3cpath fill='%23fff' d='M8.85.15a.48.48,0,0,0-.7,0L3.5,4.79.85,2.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7l3,3a.48.48,0,0,0,.7,0l5-5A.48.48,0,0,0,8.85.15Z'/%3e%3c/svg%3e");
              }

              &::after {
                border-color: #000;
                background-color: #000;
              }
            }
          }

          & + li {
            margin-top: 20px;
          }

          &.open {
            > ul {
              display: block;
            }
          }

          > ul {
            display: none;
            padding-top: 10px;
            padding-left: 30px;
            list-style-type: none;

            > li {
              > a {
                display: block;
                position: relative;
                padding-left: 30px;
                color: #000;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.45;
                text-decoration: none;
                transition: all 0.2s ease-in;

                &::before,
                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 50%;
                  width: 20px;
                  height: 20px;
                }

                &::before {
                  z-index: 1;
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: 9px 6px;
                }

                &::after {
                  border: 1px solid #e1e1e1;
                  background-color: #fff;
                }

                &:hover {
                  color: $brand-primary;
                }

                &.active {
                  &::before {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6'%3e%3cpath fill='%23fff' d='M8.85.15a.48.48,0,0,0-.7,0L3.5,4.79.85,2.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7l3,3a.48.48,0,0,0,.7,0l5-5A.48.48,0,0,0,8.85.15Z'/%3e%3c/svg%3e");
                  }

                  &::after {
                    border-color: #000;
                    background-color: #000;
                  }
                }
              }

              & + li {
                margin-top: 10px;
              }
            }
          }
        }
      }

      & + .filter {
        margin-top: 40px;
      }
    }
  }

  .catalog-sort {
    margin-bottom: 20px;

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      margin: -8px;

      label {
        display: block;
        margin: 8px;
        color: #848484;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;
        text-transform: none;
      }

      select {
        display: block;
        margin: 8px;
        border: 0;
        border-radius: 0;
        padding: 5px 15px;
        padding-right: 45px;
        width: auto;
        height: 40px;
        background-color: #f0f0f0;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3e%3cpath fill='%23000' d='M3,4,0,0H6Z'/%3e%3c/svg%3e");
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 6px 4px;
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;
        appearance: none;
      }
    }
  }

  .catalog-list {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }

    .empty {
      @include media-breakpoint-up(lg) {
        grid-column-start: 1;
        grid-column-end: span 2;
      }

      @include media-breakpoint-up(xl) {
        grid-column-start: 1;
        grid-column-end: span 3;
      }
    }
  }
}

.catalog-footer {
  padding-top: 60px;
  padding-bottom: 60px;
  color: #000;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.725;

  .inner {
    padding: 35px 15px 50px;
    background-color: #f0f0f0;

    @include media-breakpoint-up(sm) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

.catalog-produit {
  padding-top: 20px;
  padding-bottom: 60px;

  .inner {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    .visuals {
      padding-top: 20px;
      padding-bottom: 20px;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }

      .highlight {
        position: relative;
        padding: 25px;
        background-color: #f0f0f0;
        height: 330px;

        @include media-breakpoint-up(sm) {
          flex: 1 0 0%;
          width: 100%;
          max-width: 100%;
          order: 1;
          height: 570px;
        }

        .promo {
          position: absolute;
          z-index: 0;
          top: 20px;
          right: 20px;
          padding: 5px 10px;
          background-color: $brand-primary;
          color: #fff;
          font-size: 0.75rem;
          font-weight: 700;
          line-height: 1.25;
          text-transform: uppercase;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
          object-fit: contain;
        }
      }

      .thumbs {
        @include media-breakpoint-down(xs) {
          display: flex;
          flex-wrap: wrap;
          margin: -5px;
          padding-top: 10px;
        }
        @include media-breakpoint-up(sm) {
          flex: 0 0 auto;
          width: 100px;
          max-width: 100%;
          padding-right: 10px;
        }

        .thumb {
          height: 100px;
          background-color: #f0f0f0;

          @include media-breakpoint-down(xs) {
            margin: 5px;
            width: 90px;
          }

          & + .thumb {
            @include media-breakpoint-up(sm) {
              margin-top: 10px;
            }
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      color: #808080;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;

      @include media-breakpoint-up(xl) {
        padding-left: 60px;
      }

      h1 {
        margin-bottom: 10px;
        color: #000;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.35;
      }

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .price {
        margin-bottom: 25px;
        color: $brand-primary;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.25;
        text-transform: uppercase;

        .old {
          display: block;
          min-height: 18px;
          color: #b4b4b4;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.3;
          text-decoration: line-through;
        }
      }

      .selection {
        margin-bottom: 15px;
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;

        span {
          font-weight: 700;
        }
      }

      form {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;

        > #edit-variation--wrapper {
          flex: 0 0 auto;
          width: 100%;
          max-width: 100%;
          margin-bottom: 20px;

          legend {
            display: none;
          }

          .fieldset-wrapper {
            max-width: 370px;

            > div {
              display: flex;
              flex-wrap: wrap;
              margin: -2.5px;
            }

            .js-form-type-radio {
              padding: 2.5px;

              input {
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {
                  & ~ label {
                    border-color: $brand-primary;
                  }
                }
              }

              label {
                display: block;
                margin: 0;
                border: 2px solid #f0f0f0;
                padding: 5px;
                width: 70px;
                height: 70px;
                background-color: #f0f0f0;
                transition: all 0.2s ease-in;
                cursor: pointer;

                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        > .form-item-quantity {
          position: relative;
          max-width: 100px;

          input {
            display: block;
            margin: 0;
            border: 1px solid #f0f0f0;
            border-radius: 0;
            padding: 5px 35px;
            height: 50px;
            background-color: #f0f0f0;
            box-shadow: none !important;
            color: #000;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.25;
            text-align: center;
            -moz-appearance: textfield;
            transition: all 0.2s ease-in;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }

          .quantity-button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 5;
            top: 10px;
            width: 30px;
            height: 30px;
            color: $brand-primary;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.125;
            cursor: pointer;

            &.quantity-up {
              right: 5px;
            }

            &.quantity-down {
              left: 5px;
            }
          }
        }

        > input[type="submit"] {
          padding: 15px 20px;
          padding-left: 45px;
          border: 1px solid #000 !important;
          border-radius: 0;
          background-color: #000 !important;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.83 21.61'%3e%3cpath fill='%23fff' d='M17.82,20.71,16.51,6.11a.77.77,0,0,0-.77-.7H13V4.19A4.12,4.12,0,1,0,4.8,4.06V5.4H2.09a.78.78,0,0,0-.77.7L0,20.76a.78.78,0,0,0,.7.84H17.06a.78.78,0,0,0,.77-.78A.49.49,0,0,0,17.82,20.71ZM6.34,4.19a2.58,2.58,0,0,1,5.15,0h0V5.4H6.34ZM1.62,20.06,2.8,7h2V8.33a.77.77,0,0,0,.77.78.78.78,0,0,0,.77-.78h0V7h5.15V8.33a.77.77,0,0,0,.77.78A.79.79,0,0,0,13,8.33h0V7h2l1.17,13.11Z'/%3e%3c/svg%3e");
          background-position: left 15px center;
          background-repeat: no-repeat;
          background-size: 18px 22px;
          color: #fff !important;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.3;
          text-transform: uppercase;
        }

        > a {
          @extend .btn;
          padding: 14px 25px !important;
          border: 2px solid #000 !important;
          border-radius: 0 !important;
          background-color: transparent;
          color: #000;
          font-size: 0.875rem !important;
          font-weight: 700 !important;
          line-height: 1.3 !important;
          text-transform: uppercase !important;

          @include media-breakpoint-down(xs) {
            margin-top: 10px;
            width: 100%;
          }

          @include media-breakpoint-up(sm) {
            margin-left: 10px;
          }
        }
      }

      .infos {
        margin-bottom: 75px;
        padding-left: 0;
        list-style-type: none;

        > li {
          color: #838383;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25;

          strong {
            color: #000;
            font-weight: 700;
          }

          &.socials {
            > .addthis_toolbox {
              display: inline-flex;
              flex-wrap: wrap;
              margin-left: 20px;
              padding-left: 0;
              list-style-type: none;

              > li {
                color: #3c3c3c;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.25;

                > a {
                  color: inherit;
                  text-decoration: none;

                  > span {
                    display: none;
                  }
                }

                & + li {
                  margin-left: 20px;
                }
              }
            }
          }

          & + li {
            margin-top: 15px;
          }
        }
      }

      .details {
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.725;

        .title {
          margin: 0 0 40px;
          color: #000;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.25;
        }
      }
    }
  }
}

.catalog-associated {
  padding-top: 60px;
  padding-bottom: 60px;

  .title {
    margin: 0 0 55px;

    h2 {
      margin: 0 0 15px;
      color: #000;
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.35;
      text-align: center;
    }
  }

  .owl-carousel:not([class*="owl-loaded"]) {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }

  .owl-nav {
    margin: 0;

    [class*="owl-"] {
      display: block;
      position: absolute;
      top: 130px;
      margin: 0;
      border: 1px solid #d0d0d0 !important;
      border-radius: 50%;
      padding: 0 !important;
      background-color: #fff !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: 7px 13px !important;
      width: 60px;
      height: 60px;
      outline: 0;
      cursor: pointer;
    }

    button.owl-prev {
      left: -15px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.41 13.41'%3e%3cpath fill='%23707070' d='M7.41 0.71 6.71 0 0 6.71 6.71 13.41 7.41 12.71 1.41 6.71 7.41 0.71Z'/%3e%3c/svg%3e") !important;

      @include media-breakpoint-up(sm) {
        left: -30px;
      }
    }

    button.owl-next {
      right: -15px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.41 13.41'%3e%3cpath fill='%23707070' d='M0 12.71 0.71 13.41 7.41 6.71 0.71 0 0 0.71 6 6.71 0 12.71Z'/%3e%3c/svg%3e") !important;

      @include media-breakpoint-up(sm) {
        right: -30px;
      }
    }
  }
}

.cart-breadcrumb {
  padding-top: 35px;
  padding-bottom: 25px;
  background-color: #f0f0f0;

  .inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }

  ol {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style-type: none;

    > li {
      flex: 0 0 auto;
      width: 20%;
      max-width: 100%;
      display: flex;
      color: #848484;
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 1.25;
      text-align: center;

      @include media-breakpoint-up(sm) {
        width: auto;
        font-size: 1rem;
      }

      .el_inner {
        flex: 0 0 auto;
        width: 48px;
        max-width: 100%;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;

        @include media-breakpoint-up(sm) {
          width: 100px;
        }
      }

      .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        max-width: 100%;
        width: 60px;
        height: 45px;

        svg {
          display: block;
          max-width: 100%;
          height: auto;
          fill: #848484;
        }
      }

      span {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(sm) {
          white-space: nowrap;
        }
      }

      & + li {
        flex: 1 0 0%;
        width: 100%;
        max-width: 100%;

        &::before {
          content: "";
          flex: 1 0 0%;
          width: 100%;
          max-width: 100%;
          margin-top: 25px;
          border-top: 1px dotted #898989;
        }
      }

      &.active,
      &.done {
        color: $brand-primary;

        .icon {
          svg {
            fill: $brand-primary;
          }
        }

        &::before {
          margin-top: 24px;
          border-top: 3px solid $brand-primary;
        }
      }

      &.cart {
        .icon {
          svg {
            width: 30px;
          }
        }
      }

      &.account {
        .icon {
          svg {
            width: 45px;
          }
        }
      }

      &.order {
        .icon {
          svg {
            width: 55px;
          }
        }
      }

      &.payment {
        .icon {
          svg {
            width: 40px;
          }
        }
      }

      &.confirmation {
        .icon {
          svg {
            width: 45px;
          }
        }
      }
    }
  }
}

.catalog-cart {
  padding-top: 65px;
  padding-bottom: 60px;

  .title {
    margin: 0 0 50px;
    color: #000;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35;
    text-align: center;
  }

  .cart-empty {
    text-align: center;
  }

  table {
    margin: 0;

    tr {
      th,
      td {
        padding: 8px 8px;
        vertical-align: middle;

        &.alignright {
          text-align: right;
        }
      }
    }

    thead {
      @include media-breakpoint-down(sm) {
        position: absolute;
        margin: -1px;
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        @include media-breakpoint-down(sm) {
          display: block;
          border: 0;
          border-top: 1px solid #eceeef;
        }
      }

      th,
      td {
        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 0;
          padding: 4px 4px;

          &::before {
            content: attr(data-label);
            font-weight: bold;
          }
        }

        &.qty {
          white-space: nowrap;
        }

        .thumb {
          padding: 15px;
          width: 90px;
          height: 90px;
          background-color: #f0f0f0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        s {
          font-size: 80%;
          opacity: 0.6;
        }
      }
    }

    tfoot {
      background-color: #f0f0f0;

      tr {
        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      th,
      td {
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;

        @include media-breakpoint-down(sm) {
          display: block;
        }

        &.first {
          padding-top: 16px;
        }

        &.total {
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.225;
        }

        &.last {
          padding-bottom: 16px;
        }
      }

      th {
        font-weight: 700;
      }
    }

    .changeQuantity {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-size: 1.125rem;
      text-decoration: none;
    }

    .remove {
      display: inline-block;
      width: 40px;
      height: 40px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='448' height='512' viewBox='0 0 448 512'%3e%3cpath fill='%23777' d='M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z'/%3e%3c/svg%3e");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: auto 50%;
      transition: all 0.2s ease-in;

      &:hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='448' height='512' viewBox='0 0 448 512'%3e%3cpath fill='%23000' d='M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z'/%3e%3c/svg%3e");
      }
    }

    .btn-order {
      border-radius: 0;
      padding: 15px 20px;
      width: 220px;
      max-width: 100%;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .checkpromotionform {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-down(sm) {
        display: block;
        text-align: left;
      }

      .form-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: flex-start;
        }

        label {
          flex: 0 0 auto;
          width: auto;
          max-width: 100%;
          margin: 8px;
          color: #000;
          font-family: $ff-body;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.3;

          @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
          }
        }

        input {
          flex: 0 0 auto;
          width: 97px;
          max-width: 100%;
          margin: 8px;

          @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            width: 100%;
          }
        }

        .form-item--error-message {
          order: -1;
          color: #721c24;
          font-size: 0.75rem;
        }
      }

      input[type="submit"] {
        padding: 8px 8px;
        background-color: #000 !important;
        border-color: #000 !important;
        min-width: 97px;
        font-size: 0.875rem;
        line-height: 1.45;
        text-transform: capitalize;

        @include media-breakpoint-down(sm) {
          min-width: 120px;
        }
      }
    }
  }
}

.catalog-order-account {
  padding-bottom: 60px;

  .inner {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: 35% 1fr;
    }
  }

  .block {
    &:last-child {
      border-top: 1px solid #eee;
      padding-top: 40px;

      @include media-breakpoint-up(lg) {
        border-top: 0;
        border-left: 1px solid #eee;
        padding-top: 0;
        padding-left: 40px;
      }
    }
  }

  form {
    > fieldset {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      legend {
        margin-bottom: 20px;
      }

      .fieldset-wrapper {
        display: grid;
        gap: 20px;

        label.option {
          display: inline-block;
          margin: 0;
        }

        .form-wrapper,
        .form-text {
          margin: 0;
        }

        .description {
          display: block;
          margin-top: 10px;
          color: #6c757d;
          font-size: 80%;
          font-weight: 400;
        }
      }
    }

    #edit-user-account {
      #edit-password {
        > label {
          display: none;
        }

        > .form-item {
          & + .form-item {
            margin-top: 20px;
          }
        }

        .password-strength__indicator {
          &.is-weak {
            background-color: #dc3545;
          }
          &.is-fair {
            background-color: #ffc107;
          }
          &.is-good {
            background-color: #17a2b8;
          }
          &.is-strong {
            background-color: #28a745;
          }
        }

        .js-password-confirm-message {
          margin-top: 5px;

          .error {
            color: #dc3545;
            font-weight: 700;
          }
          .ok {
            color: #28a745;
            font-weight: 700;
          }
        }
      }
    }

    #edit-user-address #edit-shipping-address,
    #edit-user-billing #edit-user-billing-address > div > div {
      display: grid;
      gap: 20px;

      .address-container-inline {
        display: grid;
        gap: 20px;

        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(4, 1fr);

          > * {
            grid-column-end: span 2;
          }

          > .form-item-shipping-address-postal-code,
          > .form-item-billing-address-postal-code {
            grid-column-end: span 1;
          }

          > .form-item-shipping-address-locality,
          > .form-item-billing-address-locality {
            grid-column-end: span 3;
          }
        }

        > .form-item {
          display: block;
          margin: 0;
        }
      }
    }
  }
}

.catalog-order-shipping,
.catalog-order-payment {
  padding-bottom: 60px;

  form {
    display: grid;
    gap: 40px 20px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    .shippingleft,
    .shippingright {
      > fieldset {
        margin: 0;

        > legend {
          display: none;
        }
      }
    }

    .shipping-address,
    .payment-address {
      margin: 0 0 40px;
      color: #3b3b3b;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin: 0 0 30px;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.35;
      }

      .address {
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 35px;
        width: 100%;
        max-width: 460px;
        background-color: #fff;
      }
    }

    #edit-shipping-type--wrapper {
      margin: 0 0 40px;

      &:last-child {
        margin-bottom: 0;
      }

      legend {
        margin: 0 0 30px;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.35;
      }

      .fieldset-wrapper {
        > div {
          display: flex;
          flex-wrap: wrap;
          margin: -5px;
        }

        .js-form-type-radio {
          position: relative;
          margin: 5px;
          width: 100%;
          max-width: 240px;

          input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
              & ~ label {
                border-color: $brand-primary;
                background-color: $brand-primary;
                color: #fff;

                &[for="edit-shipping-type-delivery"] {
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3e%3cpath fill='%23fff' d='M632 384h-24V275.9c0-16.8-6.8-33.3-18.8-45.2l-83.9-83.9c-11.8-12-28.3-18.8-45.2-18.8H416V78.6c0-25.7-22.2-46.6-49.4-46.6H49.4C22.2 32 0 52.9 0 78.6v290.8C0 395.1 22.2 416 49.4 416h16.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16h195.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H632c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM460.1 160c8.4 0 16.7 3.4 22.6 9.4l83.9 83.9c.8.8 1.1 1.9 1.8 2.8H416v-96h44.1zM144 480c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm63.6-96C193 364.7 170 352 144 352s-49 12.7-63.6 32h-31c-9.6 0-17.4-6.5-17.4-14.6V78.6C32 70.5 39.8 64 49.4 64h317.2c9.6 0 17.4 6.5 17.4 14.6V384H207.6zM496 480c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-128c-26.1 0-49 12.7-63.6 32H416v-96h160v96h-16.4c-14.6-19.3-37.5-32-63.6-32z'/%3e%3c/svg%3e");
                }

                &[for="edit-shipping-type-pickup"] {
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23fff' d='M112 256h352c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16v224c0 8.8 7.2 16 16 16zM256 32h64v76.2l-32-16-32 16V32zm-128 0h96v128l64-32 64 32V32h96v192H128V32zm430.3 301.6c-9.6-8.6-22.1-13.4-35.2-13.4-12.5 0-24.8 4.3-34.6 12.2l-61.6 49.3c-1.9 1.5-4.2 2.3-6.7 2.3h-41.6c4.6-9.6 6.5-20.7 4.8-32.3-4-27.9-29.6-47.7-57.8-47.7H181.3c-20.8 0-41 6.7-57.6 19.2L85.3 352H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88l46.9-35.2c11.1-8.3 24.6-12.8 38.4-12.8H328c13.3 0 24 10.7 24 24s-10.7 24-24 24h-88c-8.8 0-16 7.2-16 16s7.2 16 16 16h180.2c9.7 0 19.1-3.3 26.7-9.3l61.6-49.2c4.2-3.4 9.5-5.2 14.6-5.2 5 0 9.9 1.7 13.8 5.2 10.1 9.1 9.3 24.5-.9 32.6l-100.8 80.7c-7.6 6.1-17 9.3-26.7 9.3H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h400.5c17 0 33.4-5.8 46.6-16.4L556 415c12.2-9.8 19.5-24.4 20-40s-6-30.8-17.7-41.4z'/%3e%3c/svg%3e");
                }
              }
            }
          }

          label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            border-radius: 4px;
            border: 1px solid #000;
            padding: 10px 20px;
            padding-left: 60px;
            min-height: 60px;
            background-position: left 20px center;
            background-repeat: no-repeat;
            background-size: 20px auto;
            color: #000;
            cursor: pointer;
            transition: all 0.2s ease-in;

            &[for="edit-shipping-type-delivery"] {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3e%3cpath fill='%23000' d='M632 384h-24V275.9c0-16.8-6.8-33.3-18.8-45.2l-83.9-83.9c-11.8-12-28.3-18.8-45.2-18.8H416V78.6c0-25.7-22.2-46.6-49.4-46.6H49.4C22.2 32 0 52.9 0 78.6v290.8C0 395.1 22.2 416 49.4 416h16.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16h195.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H632c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM460.1 160c8.4 0 16.7 3.4 22.6 9.4l83.9 83.9c.8.8 1.1 1.9 1.8 2.8H416v-96h44.1zM144 480c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm63.6-96C193 364.7 170 352 144 352s-49 12.7-63.6 32h-31c-9.6 0-17.4-6.5-17.4-14.6V78.6C32 70.5 39.8 64 49.4 64h317.2c9.6 0 17.4 6.5 17.4 14.6V384H207.6zM496 480c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-128c-26.1 0-49 12.7-63.6 32H416v-96h160v96h-16.4c-14.6-19.3-37.5-32-63.6-32z'/%3e%3c/svg%3e");
            }

            &[for="edit-shipping-type-pickup"] {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3e%3cpath fill='%23000' d='M112 256h352c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16v224c0 8.8 7.2 16 16 16zM256 32h64v76.2l-32-16-32 16V32zm-128 0h96v128l64-32 64 32V32h96v192H128V32zm430.3 301.6c-9.6-8.6-22.1-13.4-35.2-13.4-12.5 0-24.8 4.3-34.6 12.2l-61.6 49.3c-1.9 1.5-4.2 2.3-6.7 2.3h-41.6c4.6-9.6 6.5-20.7 4.8-32.3-4-27.9-29.6-47.7-57.8-47.7H181.3c-20.8 0-41 6.7-57.6 19.2L85.3 352H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88l46.9-35.2c11.1-8.3 24.6-12.8 38.4-12.8H328c13.3 0 24 10.7 24 24s-10.7 24-24 24h-88c-8.8 0-16 7.2-16 16s7.2 16 16 16h180.2c9.7 0 19.1-3.3 26.7-9.3l61.6-49.2c4.2-3.4 9.5-5.2 14.6-5.2 5 0 9.9 1.7 13.8 5.2 10.1 9.1 9.3 24.5-.9 32.6l-100.8 80.7c-7.6 6.1-17 9.3-26.7 9.3H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h400.5c17 0 33.4-5.8 46.6-16.4L556 415c12.2-9.8 19.5-24.4 20-40s-6-30.8-17.7-41.4z'/%3e%3c/svg%3e");
            }

            &:hover {
              border-color: $brand-primary;
              color: $brand-primary;
            }
          }
        }
      }
    }

    #edit-shipping-delivery--wrapper,
    #edit-shipping-pickup--wrapper {
      margin: 0;

      legend {
        margin: 0 0 20px;
        color: #000;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.35;
      }

      .fieldset-wrapper {
        .js-form-type-radio {
          position: relative;

          input {
            position: absolute;
            z-index: -1;
            opacity: 0;

            &:checked {
              & ~ label {
                color: $brand-primary;

                &::before {
                  border-color: $brand-primary;
                  background-color: $brand-primary;
                }

                &::after {
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
                }
              }
            }
          }

          label {
            display: block;
            position: relative;
            margin: 0;
            padding: 10px 0;
            padding-left: 30px;
            color: #000;
            line-height: 1.5;
            cursor: pointer;
            transition: all 0.2s ease-in;

            span {
              color: #848484;
              font-style: italic;
              text-transform: none;
            }

            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 15px;
              left: 0;
              border: 1px solid #adb5bd;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              background-color: #fff;
              pointer-events: none;
              transition: all 0.15s ease-in-out;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              top: 15px;
              left: 0;
              width: 20px;
              height: 20px;
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: 50% 50%;
              transition: all 0.15s ease-in-out;
            }

            &:hover {
              color: $brand-primary;
            }
          }
        }
      }
    }

    .payment-options {
      > fieldset {
        margin: 0;

        legend {
          margin: 0 0 30px;
          color: #000;
          font-size: 2rem;
          font-weight: 700;
          line-height: 1.35;
        }

        .fieldset-wrapper {
          > div {
            display: flex;
            flex-wrap: wrap;
            margin: -10px -5px;
          }

          .js-form-type-radio {
            position: relative;
            margin: 10px 5px;
            width: 100%;

            @include media-breakpoint-up(sm) {
              max-width: 180px;
            }

            input {
              position: absolute;
              z-index: -1;
              opacity: 0;

              &:checked {
                & ~ label {
                  border-color: $brand-primary;
                  color: $brand-primary;

                  &::before {
                    border-color: $brand-primary;
                    background-color: $brand-primary;
                  }

                  &::after {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
                  }
                }
              }
            }

            label {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              margin: 0;
              border: 1px solid #000;
              padding: 10px 20px;
              width: 100%;
              height: 110px;
              color: #000;
              text-align: center;

              &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: -15px;
                left: 50%;
                border: 1px solid #adb5bd;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                background-color: #fff;
                transform: translateX(-50%);
                pointer-events: none;
                transition: all 0.15s ease-in-out;
              }

              &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: -15px;
                left: 50%;
                width: 30px;
                height: 30px;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 50% 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }

    .form-item-comment {
      > * {
        max-width: 520px;
      }

      label {
        margin: 0 0 15px;
        padding-left: 30px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.92 19.24'%3e%3cpath fill='%23000' d='M18,0H2.88A2.88,2.88,0,0,0,0,2.88v9.28A2.88,2.88,0,0,0,2.87,15v4.21L8.92,15H18a2.89,2.89,0,0,0,2.88-2.87V2.88A2.89,2.89,0,0,0,18,0Zm1.65,12.16A1.66,1.66,0,0,1,18,13.81H8.53L4.09,16.9V13.81H2.88a1.66,1.66,0,0,1-1.65-1.65V2.88A1.65,1.65,0,0,1,2.88,1.23H18a1.65,1.65,0,0,1,1.65,1.65ZM5.6,5.56h9.72V4.33H5.6Zm0,2.61h9.72V7H5.6Zm0,2.62h9.72V9.56H5.6Z'/%3e%3c/svg%3e");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 21px 19px;
        color: #000;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.675;
        text-transform: uppercase;
      }

      textarea {
        border: 1px solid #dddddd;
        border-radius: 5px;
        background-color: #fff;
      }
    }

    > .form-item-legal {
      padding-left: 25px;

      @include media-breakpoint-up(lg) {
        grid-column-start: 1;
        grid-column-end: span 2;
      }

      > * {
        max-width: 520px;
      }

      label {
        display: block;
        margin: 0;
        color: #000;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.675;
        text-transform: uppercase;
      }

      input {
        float: left;
        margin-top: 3px;
        margin-left: -25px;
        width: 14px;
        height: 14px;
      }
    }
  }

  #edit-back {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #666 !important;
    border-color: #666 !important;
    color: #fff !important;

    @include media-breakpoint-down(md) {
      margin-right: auto;
    }

    @include media-breakpoint-up(lg) {
      margin-left: auto;
    }
  }

  #edit-submit {
    margin-right: auto;

    @include media-breakpoint-down(md) {
      margin-top: -30px;
    }
  }
}

.catalog-order-payment-inshop,
.catalog-order-payment-bank,
.catalog-order-confirm {
  padding-top: 65px;
  padding-bottom: 60px;
}

.customer-orders {
  padding-top: 65px;
  padding-bottom: 60px;

  table {
    @extend .table;
    margin-bottom: 0;
  }
}

.customer-order {
  padding-top: 65px;
  padding-bottom: 60px;

  .back-to-list {
    margin-bottom: 40px;
    text-align: right;

    .btn {
      padding: 8px 20px;
      font-size: 0.875rem;
      line-height: 1.45;
    }
  }

  hr {
    border-color: #ccc;
  }

  .row {
    .col-md-6:first-child {
      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }
    }
  }

  table {
    margin-top: 20px;
    margin-bottom: 0;

    tr {
      th,
      td {
        padding: 8px 8px;
        vertical-align: middle;

        &.alignright {
          text-align: right;
        }
      }
    }

    thead {
      @include media-breakpoint-down(sm) {
        position: absolute;
        margin: -1px;
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        white-space: nowrap;
      }
    }

    tbody {
      tr {
        @include media-breakpoint-down(sm) {
          display: block;
          border: 0;
          border-top: 1px solid #eceeef;
        }
      }

      th,
      td {
        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 0;
          padding: 4px 4px;

          &::before {
            content: attr(data-label);
            font-weight: bold;
          }
        }

        &.qty {
          white-space: nowrap;
        }

        .thumb {
          padding: 15px;
          width: 90px;
          height: 90px;
          background-color: #f0f0f0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        s {
          font-size: 80%;
          opacity: 0.6;
        }
      }
    }

    tfoot {
      background-color: #f0f0f0;

      tr {
        @include media-breakpoint-down(sm) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      th,
      td {
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;

        @include media-breakpoint-down(sm) {
          display: block;
        }

        &.first {
          padding-top: 16px;
        }

        &.total {
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.225;
        }

        &.last {
          padding-bottom: 16px;
        }
      }

      th {
        font-weight: 700;
      }
    }
  }
}
