.accordion {
	.card-header {
		border-bottom: 1px solid #E5E5E5;
		h5 {
			margin: 0;
			font-size: 1.15rem;
			line-height: 1.1em;
			a {
				display: block;
				padding: 15px 0 20px;
				padding-right: 40px;
				background-image: url(../../../default/files/icons/icon-less.svg);
				background-position: right 10px center;
				background-repeat: no-repeat;
				background-size: 16px auto;
				color: #212121;
				text-decoration: none !important;
				@include transition(all .3s ease-out);
				&.collapsed {
					padding-bottom: 15px;
					background-image: url(../../../default/files/icons/icon-more.svg);
				}
			}
		}
	}
	.card-body {
		padding-top: 15px;
		padding-bottom: 40px;
	}
}
