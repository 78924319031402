// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
  color: #999;
  font-family: $ff-headings;
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;

  h2 {
    margin-bottom: 20px;
    color: #000;
    font-family: $ff-headings;
    font-size: 0.88rem;
    font-weight: 600;
    line-height: 1.71;
    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    li {
      & + li {
        margin-top: 10px;
      }
    }
  }

  a:not([class*="btn"]) {
    color: $brand-primary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .newsletter {
    color: #000;
    font-size: 1rem;
    line-height: 1.38;
    .wrapper {
      padding-top: 75px;
      padding-bottom: 70px;
      border-bottom: 1px solid #979797;
    }
    .inner {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        max-width: 840px;
      }
    }
    h2 {
      margin: 0;
      font-size: 1.13rem;
      line-height: 1.22;
    }
    .title {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
    .form {
      form {
        display: flex;
      }
      .form-text {
        margin: 0;
        border: 1px solid #c5c5c5;
        padding: 5px 25px;
        max-width: 420px;
        height: 50px;
        color: #000;
        font-family: $ff-headings;
        font-size: 0.88rem;
        line-height: 1.29;
        &::placeholder {
          color: #000;
        }
      }
      [type="submit"] {
        border-radius: 0;
        padding: 14px 22px;
        font-family: $ff-headings;
        font-size: 0.88rem;
        line-height: 1.29;
      }
    }
  }

  .top {
    padding-top: 55px;
    padding-bottom: 70px;
    .col-md-4,
    .col-md-6 {
      & + div {
        @include media-breakpoint-down(sm) {
          margin-top: 30px;
        }
      }
    }
  }

  .bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $brand-primary;
    color: #fff;

    .inner {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    a:not([class*="btn"]) {
      color: #fff;
    }
  }

  .copyright-webup {
    a {
      display: flex;
      align-items: center;
      text-decoration: none !important;
      svg {
        margin-left: 5px;
        opacity: 0.8;
        @include transition(all 0.35s);
        path {
          fill: #fff;
        }
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }

  .social {
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: #fff;
      color: $brand-primary !important;
      text-decoration: none !important;
      .fa {
        font-size: 1rem;
        line-height: 1;
      }
      .icon {
        width: 1rem;
        height: 1rem;
      }
      & + a {
        margin-left: 5px;
      }
    }
  }
}
