.wu-fullscreen {
	position: relative;
	background-color: #000;

	.bg {
		opacity: .6;
	}

	.content {
		position: relative;
		z-index: 5;
	}

	.container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-height: 695px;
	}

	&.text-align_left {
		.bg {
			@include media-breakpoint-down(md) {
				background-position: left center;
			}
		}
	}
	&.text-align_right {
		.container {
			justify-content: flex-end;
		}
		.bg {
			@include media-breakpoint-down(md) {
				background-position: right center;
			}
		}
	}
	&.text-align_center {
		.container {
			justify-content: center;
		}
	}

	.md-width {
		margin-left: 0;
		margin-right: 0;
		padding-top: 30px;
		padding-bottom: 30px;
		max-width: 330px;
		color: #fff;
		font-size: 0.875rem;
		font-weight: 500;
		line-height: 1.85;
	}


	h2 {
	    margin-bottom: 25px;
	    font-size: 0.9rem;
	    letter-spacing: 3px;
	    line-height: 2.55em;
	    text-transform: uppercase;
	  }
	  p {
	    font-size: 1.9rem;
	    line-height: 1.5em;
	  }
	  a:not([class*="btn"]) {
	    font-size: 0.75rem;
	    letter-spacing: 2px;
	    text-transform: uppercase;
	  }
	h2,h3,h4,h5,h6,
	a:not([class*="btn"]) {
		color: #fff;
	}
}
