.wu-page_references {
  padding-top: 55px;
  padding-bottom: 370px;
}

.path-references {
  .wu-page_references {
    padding-top: 0;
    padding-bottom: 0;

    .wu-results {
      padding-top: 55px;
      padding-bottom: 70px;
    }
  }
}
