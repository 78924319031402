.item-product {
	@extend .item;
	border: 0;
	border-radius: 0 0 4px 4px;
	height: 405px;
	background-color: #F6F4F0;
	.img-wrapper {
		height: 206px;
	}
	.content {
		padding: 40px;
		color: $body-color;
		@media (max-width: 1319px) {
			padding: 25px;
		}
		@include media-breakpoint-down(sm) {
            padding: 15px;
        }
		h3 {
			color: $headings-color;
			font-size: 1.15rem;
			line-height: 1.3em;
		}
	}
}