.sliding-popup-bottom,
.sliding-popup-top {
  left: 0;
  padding: 30px 0;
  width: 100%;
  background-color: $brand-primary;
  color: #fff;
  font-size: 0.875rem;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    color: inherit;
  }

  p,
  ul,
  ol {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not([class*="btn"]) {
    color: inherit;
  }

  .find-more-button {
    @extend .btn;
    @extend .btn-lined-white;

    &.hidden {
      display: none;
    }
  }

  .agree-button,
  .eu-cookie-compliance-reject-button,
  .eu-cookie-withdraw-button {
    @extend .btn;
    @extend .btn-white;

    &.hidden {
      display: none;
    }
  }

  .decline-button,
  .eu-cookie-compliance-save-preferences-button {
    @extend .btn;
    @extend .btn-link;
    margin-top: 20px;
    border-color: #fff !important;
    color: #fff !important;

    &:hover,
    &:focus {
      border-color: transparent !important;
    }

    &.hidden {
      display: none;
    }
  }
}

.eu-cookie-compliance-content {
  display: block;
}

.eu-cookie-compliance-message {
  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
  }
}

.eu-cookie-withdraw-tab {
  @extend .btn;
  @extend .btn-white;
  border-radius: 15px 15px 0 0 !important;
  padding: 0px 5px !important;
  text-transform: none !important;

  &:hover {
    box-shadow: none !important;
    transform: translate(-50%, -100%) !important;
  }
}

.eu-cookie-compliance-banner--categories {
  .eu-cookie-compliance-categories {
    @include media-breakpoint-up(md) {
      float: right;
      width: 100%;
      max-width: 30%;
    }

    .eu-cookie-compliance-category {
      > div {
        display: block;
        position: relative;
        padding-left: 35px;
        min-height: 30px;

        input {
          position: absolute;
          z-index: -1;
          opacity: 0;

          &:checked {
            & ~ label {
              &::before {
                background-color: $brand-primary;
                color: #fff;
              }
            }
          }
        }

        label {
          display: block;
          margin-bottom: 0;
          padding-top: 4px;
          padding-bottom: 4px;
          color: #fff;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.575;
          text-transform: none;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            border-radius: 0;
            border: 1px solid #fff;
            width: 22px;
            height: 22px;
            background-color: #fff;
            pointer-events: none;
            user-select: none;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 22px;
            height: 22px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 50% 50%;
          }
        }

        input {
          &:checked {
            & ~ label {
              &::before {
                border-color: #fff;
                background-color: #fff;
              }
              &::after {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'%3E%3Cpath fill='%23000000' d='M9.69.31a1,1,0,0,0-1.47,0l-4,4.11L1.78,1.93a1,1,0,0,0-1.47,0,1.09,1.09,0,0,0,0,1.52L3.47,6.69A1,1,0,0,0,4.21,7,1,1,0,0,0,5,6.69L9.69,1.82A1.08,1.08,0,0,0,9.69.31Z'/%3E%3C/svg%3E");
              }
            }
          }
        }
      }
    }
  }
  .eu-cookie-compliance-buttons {
    padding-top: 20px;
    clear: both;
    float: none;
    max-width: none;
  }
}
