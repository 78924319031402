// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------

.wu-page_home, .page-node-type-home, .path-frontpage {
	.main-wrapper, main {
		.wu-section:not([class*="wu-slider"]):not([class*="wu-cta"]) {
			&:nth-child(even) {
				background-color: #F6F4F0;
			}
		}
	}
  .wu-content {
    padding-top: 110px;
    padding-bottom: 55px;
    line-height: 2.25em;
    text-align: center;
    h2 {
      margin: 0 0 15px;
      &:after {
        content: "";
        display: block;
        margin: 20px auto 0;
        width: 55px;
        height: 2px;
        background-color: $brand-primary;
      }
    }
    a:not([class*="btn"]) {
      color: $brand-primary;
      font-size: 0.9rem;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}
