// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------

.wu-banner {
	position: relative;
	height: 350px;
	@include media-breakpoint-down(sm) {
		padding: 90px 0 60px;
        height: auto;
        min-height: 350px;
    }
	.title {
		position: relative;
		z-index: 5;
		text-align: center;
		@include media-breakpoint-up(sm) {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			-ms-transform: translate(0,-50%);
			-webkit-transform: translate(0,-50%);
			transform: translate(0,-50%);
		}
	}
	h1 {
		margin: 0;
	}
	&.banner--title {
		&_white {
			h1 {
				color: #fff;
			}
		}
		&_black {
			h1 {
				color: #000;
			}
		}
		&_gray {
			h1 {
				color: #808080;
			}
		}
		&_primary {
			h1 {
				color: $brand-primary;
			}
		}
		&_secondary {
			h1 {
				color: $brand-secondary;
			}
		}
	}
	&.banner--bg {
		&_image {
			/*background-color: #000;
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: #BB957B;
				opacity: .2;
			}
			.bg {
				opacity: .6;
			}*/
			&__transparent {
				background-color: transparent;
				.bg {
					opacity: 1;
				}
			}
			&__black {
				background-color: #000;
				.bg {
					opacity: .6;
				}
			}
			&__white {
				background-color: #fff;
				.bg {
					opacity: .6;
				}
			}
		}
		&_gray {
			height: 250px;
			background-color: #F3F3F3;
		}
		&_primary {
			background-color: $brand-primary;
		}
		&_gradient {
			height: 315px;
			background-color: $brand-primary;
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: -moz-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -webkit-linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				background: linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
				opacity: .4;
			}
		}
	}
}