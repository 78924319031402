.wu-leaderboard {
    & + .wu-intro {
      margin-top: -50px;
    }

    .leaderboard {
        position: relative;
        width: 100%;
        height: 600px;
        @include media-breakpoint-down(md) {
            background-color: #000;
        }
        @include media-breakpoint-down(xs) {
            height: 470px;
        }
    }

    .inner {
        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &:before {
            z-index: 2;
            background-color: #BB957B;
            opacity: .2;
        }
        &:after {
            z-index: 1;
            background-color: #000000;
            opacity: .1;
        }
    }

    .title {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 0;
        width: 100%;
        color: #fff;
        font-size: 1.5rem;
        font-family: $headings-font-family;
        font-weight: 400;
        line-height: 2.1em;
        text-align: center;
        -ms-transform: translate(0,-50%);
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%);
        h1,.h1 {
            margin: 0;
            color: #fff;
        }
        p {
          margin: 0;
        }
        a.btn {
            margin-top: 35px;
            padding: 18px 62px;
            border-radius: 30px;
            text-shadow: none;
            text-transform: none;
        }
        .wrapper {
            @include media-breakpoint-down(lg) {
                padding-left: 65px;
                padding-right: 65px;
            }
            @include media-breakpoint-down(xs) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        @include media-breakpoint-down(md) {
            display: none;
        }
        iframe {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            opacity: 0;
            @include transition(opacity .5s);
            &.active {
                opacity: 1;
            }
        }
    }
    .bg {
        opacity: .7;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
