// Form
// =============================================================================

label {
  color: $brand-primary;
  font-family: $headings-font-family;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3em;
  text-transform: uppercase;
  &:not([class*="form-check-label"]) {
    display: block;
    margin: 0 0 10px;
  }
}

.form-actions {
  .btn {
    margin-top: 25px;
    padding-left: 45px;
    padding-right: 45px;
  }
}

.form-control,
.form-text,
.form-textarea,
.form-email,
.form-tel,
.form-select,
.form-number {
  display: block;
  width: 100%;
  height: 40px;
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  color: #979797;
  background-color: #fff;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #979797;
  border-radius: 0;
  box-shadow: none;
  @include transition(all ease-out 0.3s);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    background-color: inherit;
    border-color: darken(#979797, 20%);
    outline: none;
  }

  // Placeholder
  &::placeholder {
    color: $brand-primary;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: 40px;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: #979797;
    background-color: #fff;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
}

.form-select[multiple],
.form-select[size],
.form-textarea {
  height: auto;
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group,
.form-wrapper:not([class*="form-actions"]),
.js-form-type-email,
.js-form-type-item {
  margin-bottom: 20px;
}

/* .form-text {
  display: block;
  margin-top: 5px;
} */

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: block;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    .form-check-label {
      color: #868e96;
    }
  }
}

.form-check-label {
  padding-left: 20px;
  margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-input {
  position: absolute;
  margin-top: 0;
  margin-left: -20px;
}

// Custom
.custom-control {
  position: relative;
  margin-top: 15px;
  padding-left: 40px;
  cursor: pointer;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-control-indicator {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: #ececec;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      width: 10px;
      height: 10px;
      background-color: $brand-primary;
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      transform: scale(0);
      @include transition(all ease-out 0.3s);
    }
  }
  .custom-control-description {
    display: inline-block;
    padding: 5px 0;
    line-height: 1.25em;
  }
  &.custom-checkbox {
    .custom-control-indicator {
      border-radius: 10px;
      &:before {
        border-radius: 3px;
      }
    }
    .custom-control-input:checked {
      & ~ .custom-control-indicator {
        &:before {
          -ms-transform: scale(1);
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }
}
