.wu-intro {
  // margin-top: -50px;
  &.img-align_right {
     .bg-wrapper, .img-wrapper {
      @include media-breakpoint-up(lg) {
        left: 58.33333%;
      }
     }
     .content {
      @include media-breakpoint-up(lg) {
        right: 41.66667%;
      }
     }
  }
  .bg-wrapper {
    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #000;
      @include media-breakpoint-down(md) {
        padding-bottom: 100%;
        height: 0;
      }
    }
  }
  .content {
    padding-top: 135px;
    padding-bottom: 55px;
    @include media-breakpoint-down(md) {
      padding-top: 60px;
    }
  }
  h2 {
    margin-bottom: 25px;
    font-size: 0.9rem;
    letter-spacing: 3px;
    line-height: 2.55em;
    text-transform: uppercase;
  }
  p {
    font-size: 1.9rem;
    line-height: 1.5em;
  }
  a:not([class*="btn"]) {
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
