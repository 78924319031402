.wu-form {
	padding-top: 65px;
	padding-bottom: 65px;
	.row {
		margin-bottom: 50px;
		&:last-child {
			margin-bottom: 0;
		}
		.col-md-6 {
			&:first-child {
				@include media-breakpoint-down(sm) {
					margin-bottom: 40px;
				}
			}
		}
	}
	a:not([class*="btn"]) {
		color: $brand-secondary;
	}
	img {
		max-width: 100%;
    	height: auto !important;
	}
	#edit-preview {
		display: none;
	}
	&.animated {
		animation-fill-mode: backwards!important;
	}
}