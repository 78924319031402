.wu-slider {
  & + .wu-intro {
    margin-top: -50px;
  }
  .slider--title {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 1.5rem;
    font-family: $headings-font-family;
    font-weight: 400;
    line-height: 1.15em;
    text-align: center;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    h1,
    .h1 {
      margin: 0;
      color: #fff;
    }
    p {
      margin: 0;
    }
    a.btn {
      margin-top: 35px;
      padding: 18px 62px;
      border-radius: 30px;
      text-shadow: none;
      text-transform: none;
      @include media-breakpoint-down(xs) {
        margin-top: 15px;
      }
    }
    .wrapper {
      @include media-breakpoint-down(lg) {
        padding-left: 65px;
        padding-right: 65px;
      }
      @include media-breakpoint-down(xs) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(xs) {
      height: 470px;
    }
  }

  .swiper-slide {
    box-sizing: border-box;
    /*&:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        &:before {
            z-index: 2;
            background-color: #BB957B;
            opacity: .2;
        }
        &:after {
            z-index: 1;
            background-color: #000000;
            opacity: .1;
        }*/

    .background {
      &,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      img {
        object-fit: cover;
      }
    }
  }

  // navigation
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    z-index: 10;
    margin-top: -25px;
    top: 50%;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    background-image: none !important;
    text-align: center;
    @include transition(all 0.2s);
    @include media-breakpoint-down(xs) {
      top: auto;
      bottom: 0;
    }
    &:before {
      content: "";
      font-family: "fontAwesome";
      font-size: 1.75rem;
      line-height: 50px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 0;
    &:before {
      content: "\f104";
    }
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0;
    &:before {
      content: "\f105";
    }
  }

  // pagination
  .swiper-pagination {
    position: absolute;
    z-index: 10;
    font-size: 0;
    text-align: center;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 35px;
    left: 0;
    width: 100%;
    @include media-breakpoint-down(xs) {
      bottom: 20px;
    }
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    opacity: 0.3;
    @include transition(all 0.2s);
    &:hover {
      opacity: 0.6;
    }
  }
  .swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1 !important;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 15px;
  }
}
