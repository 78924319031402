.item-reference,
.item-references {
  @extend .item;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 420px;
  .img-wrapper,
  .logo-wrapper {
    height: 293px;
  }
  .content {
    padding: 15px 10%;
    h3 {
      color: #646464;
    }
  }
}
