.wu-news {
	padding-top: 80px;
	padding-bottom: 85px;
	h2 {
		color: $brand-primary !important;
		text-align: center;
	}
	.item-news {
    @extend .featured;
		&.featured {
			.img-wrapper {
				&:before {
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
					opacity: .5;
				}
				.bg {
					opacity: 1;
				}
			}
			.content {
				.date {
					color: #fff;
				}
			}
			&:hover {
				.img-wrapper {
					&:before, .bg {
						opacity: 0;
					}
				}
			}
		}
	}
}
