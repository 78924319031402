// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Reassign color vars to semantic color scheme
$brand-primary:             #0069A6;
$brand-secondary: 			#00BFD6;


// Body
//
// Settings for the `<body>` element.

$body-bg:       #fff;
$body-color:    #666;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary;
$link-decoration:       underline;
$link-hover-color:      $brand-primary;
$link-hover-decoration: none;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 970px,
  xl: 1170px
);


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$ff-headings: 'Montserrat', sans-serif;
$ff-body: 'Roboto', sans-serif;

$font-family-base:       $ff-body;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$line-height-base: 1.75;

$font-size-h1: 3rem; //48px
$font-size-h2: 1.5rem; //24px
$font-size-h3: 1.4rem; //22px
$font-size-h4: 1.25rem; //20px
$font-size-h5: 1.15rem; //18px
$font-size-h6: 1rem; //16px

$headings-font-family:   $ff-headings;
$headings-font-weight:   400;
$headings-line-height:   1.5;
$headings-color:         #212121;


//==================================================================================================//
// Module loading                                                                                   //
//==================================================================================================//
// To include an animation module in the compiled css file, simply set it's attribute below to true //
// (all modules set to false will not be included)                                                  //
// The idea here is to only load the animations you want to use on a per project basis              //
// This helps keep the compiled css file at its leanest                                             //
//==================================================================================================//
// Hugely influenced and inspired by @csswizardry's inuit.css _vars.scss file                       //
//==================================================================================================//

$use-all                :   true; // all animations status
