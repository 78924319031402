// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------


h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-top: 0;
	margin-bottom: 35px;
	&:last-child {
		margin-bottom: 0;
	}
	&.couleur_1 {
		color: $brand-primary;
	}
	&.couleur_2 {
		color: $brand-secondary;
	}
	&.noir {
		color: #000;
	}
	&.blanc {
		color: #fff;
	}
	&.gris {
		color: #808080;
	}
}

h1,.h1 {
	font-weight: 700;
	line-height: 1.05em;
	@include media-breakpoint-down(sm) {
        font-size: 2.25rem;
    }
}
h2,.h2 {
	&.big {
		margin-bottom: 55px;
		color: #1D1D1B;
		font-size: 2rem;
		line-height: 1.15em;
		@include media-breakpoint-down(xs) {
	        font-size: 1.9rem;
	    }
	}
}
h5,.h5 {
	line-height: 1.3em;
}

p, ul, ol {
	margin-top: 0;
    margin-bottom: 40px;
    &:last-child {
		margin-bottom: 0;
	}
	&.couleur_1 {
		color: $brand-primary;
	}
	&.couleur_2 {
		color: $brand-secondary;
	}
	&.noir {
		color: #000;
	}
	&.blanc {
		color: #fff;
	}
	&.gris {
		color: #808080;
	}
}

.lead {
	margin-bottom: 35px;
	color: $brand-secondary;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.5em;
}


hr {
    margin-top: 60px;
    margin-bottom: 40px;
    border: 0;
    border-top: 1px solid #979797;
}

.link {
	color: $brand-primary;
	font-size: 0.75rem;
	letter-spacing: 2px;
	line-height: 2em;
	text-decoration: underline;
	text-transform: uppercase;
}

.iframe-embed {
  display: block;
  position: relative;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: percentage(9 / 16);
  }

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
}