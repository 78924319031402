.page-node-type-produit,
.path-taxonomy,
.path-shop,
.path-cart {
  padding-top: 70px;

  @include media-breakpoint-up(lg) {
    padding-top: 150px;
  }

  .main-header {
    background-color: #000;
  }

  .shareBoxWrapper,
  .cta-fixed {
    display: none;
  }
}

.page-node-type-produit,
.path-cart {
  .wu-banner {
    display: none;
  }
}

.path-taxonomy,
.path-shop {
  .wu-banner {
    padding: 105px 0 0;
    min-height: 0;
    height: 400px;

    .title {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
      text-align: left;
      transform: none;
    }

    h1 {
      margin: 0 0 25px;
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.125;
    }
  }
}

.path-shop {
  .customer-edit {
    margin-left: auto;
    margin-right: auto;
    padding-top: 65px;
    padding-bottom: 60px;
    max-width: 960px;

    form {
      > fieldset {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        legend {
          margin-bottom: 20px;
        }

        .fieldset-wrapper {
          display: grid;
          gap: 20px 40px;

          label.option {
            display: inline-block;
            margin: 0;
          }

          .form-wrapper,
          .form-text {
            margin: 0;
          }

          .description {
            display: block;
            margin-top: 10px;
            color: #6c757d;
            font-size: 80%;
            font-weight: 400;
          }
        }
      }

      #customer_shipping #edit-shipping-address,
      #customer_billing #edit-billing-address {
        display: grid;
        gap: 20px 40px;

        .address-container-inline {
          display: grid;
          gap: 20px 40px;

          @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);

            > * {
              grid-column-end: span 2;
            }

            > .form-item-shipping-address-postal-code,
            > .form-item-billing-address-postal-code {
              grid-column-end: span 1;
            }

            > .form-item-shipping-address-locality,
            > .form-item-billing-address-locality {
              grid-column-end: span 3;
            }
          }

          > .form-item {
            display: block;
            margin: 0;
          }
        }
      }
    }
  }
}
