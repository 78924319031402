.item-news {
	@extend .item;
	height: 475px;
	.img-wrapper {
		height: 237.5px;
	}
	.content {
		padding: 40px 10% 25px;
		height: 237.5px;
		font-size: 0.875rem;
		.inner {
			position: relative;
			height: 100%;
			overflow: hidden;
		}
	}
	&.featured {
		border: 0;
		.img-wrapper {
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			height: auto;
			&:before {
				content: "";
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.63) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.63) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.63) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a1000000',GradientType=0 );
				@include transition(all .35s ease-out);
			}
			.bg {
				opacity: .9;
			}
		}
		.content {
			position: absolute;
			z-index: 5;
			bottom: 0;
			left: 0;
			padding-top: 30px;
			padding-bottom: 30px;
			width: 100%;
			height: auto;
			color: #fff;
			font-family: $headings-font-family;
			h3, .summary, p:not([class*="date"]):not([class*="link"]) {
				// display: inline;
			}
			h3 {
				color: #fff !important;
			}
			.date, .link {
				font-family: $font-family-base;
			}
			.date {
				@include transition(all .35s ease-out);
			}
		}
		&:not([class*="disabled"]):hover {
			.img-wrapper {
				background-color: $brand-primary;
				&:before, .bg {
					opacity: 0;
				}
			}
			.content {
				.date {
					color: #fff;
				}
			}
		}
	}
}
