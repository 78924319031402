// Import bootstrap librairy
@import "base/bootstrap";

// Import Animate library
@import "../../node_modules/animate-sass/_animate.scss";

// Import FontAwesome library
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

// Import Owl-carousel library
@import "../../node_modules/owl.carousel/src/scss/owl.carousel",
  "../../node_modules/owl.carousel/src/scss/owl.theme.default";

// 1. Configuration and base stuff
@import "base/drupal", "base/fonts";

// 2. Helpers
@import "helpers/easing", "helpers/animations";

// 3. Layouts
@import "layouts/header", "layouts/header/navigation", "layouts/header/banner",
  "layouts/footer", "layouts/main", "layouts/page/accordion",
  "layouts/page/catalog", "layouts/page/content", "layouts/page/cta",
  "layouts/page/form", "layouts/page/fullscreen", "layouts/page/gallery",
  "layouts/page/highlight", "layouts/page/intro", "layouts/page/news",
  "layouts/page/numbers", "layouts/page/products", "layouts/page/realisations",
  "layouts/page/reviews", "layouts/page/rentiteasy", "layouts/page/services",
  "layouts/page/slider", "layouts/page/team", "layouts/page/type",
  "layouts/page/video";

// 4. Components
@import "components/accordion", "components/alert", "components/appointment",
  "components/ckeditor", "components/cookie", "components/bg",
  "components/buttons", "components/dont-break-out", "components/filter",
  "components/forms", "components/item", "components/item-event",
  "components/item-gallery", "components/item-news", "components/item-product",
  "components/item-reference", "components/item-service", "components/modal", "components/pager";

// 5. Page-specific styles
@import "pages/homepage", "pages/page-contact", "pages/page-content",
  "pages/page-event", "pages/page-news", "pages/page-products",
  "pages/page-references", "pages/page-rentiteasy", "pages/page-services",
  "pages/page-shop", "pages/page-webform";
