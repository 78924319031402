.alert {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  padding-right: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  a:not([class*="btn"]) {
    font-weight: 700;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 10px 20px;
    background-color: transparent;
    color: inherit;
    opacity: 0.5;
    outline: 0;
    cursor: pointer;
    transition: all 0.2s ease-in;

    svg {
      display: block;
      max-width: 100%;
      width: 15px;
      height: auto;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &.alert-success {
    border-color: #c3e6cb;
    background-color: #d4edda;
    color: #155724;

    a:not([class*="btn"]) {
      color: #0b2e13;
    }

    .close {
      svg {
        fill: #155724;
      }
    }
  }

  &.alert-danger {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;

    a:not([class*="btn"]) {
      color: #491217;
    }

    .close {
      svg {
        fill: #721c24;
      }
    }
  }
}

.messages {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    max-height: 25vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
