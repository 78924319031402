.item-gallery {
	display: block;
	position: relative;
	border-radius: 4px;
	background-color: #000;
	overflow: hidden;
	outline: 0 !important;
	&:before, &:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		@include transition(all .35s ease-out);
	}
	&:before {
		z-index: 5;
		background-color: $brand-primary;
	}
	&:after {
		z-index: 10;
		background-image: url(../../../default/files/icons/icon-plus.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 72px auto;
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&:hover {
		&:before, &:after {
			opacity: .9;
			visibility: visible;
		}
		&:after {
			-ms-transform: translateY(0);
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}
}
