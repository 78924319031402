@keyframes redrawn {
  0% {
    transform-origin: right center;
    transform: scale(1, 1);
  }

  49% {
    transform-origin: right center;
    transform: scale(0, 1);
  }

  50% {
    background: #fff;
    transform-origin: left center;
    transform: scale(0, 1);
  }

  100% {
    background: color(content-link);
    transform-origin: left center;
    transform: scale(1, 1);
  }
}

@-webkit-keyframes mask-play {
  from {
    -webkit-mask-position: 0% 0;
            mask-position: 0% 0;
  }
  to {
    -webkit-mask-position: 100% 0;
            mask-position: 100% 0;
  }
}

@keyframes mask-play {
  from {
    -webkit-mask-position: 0% 0;
            mask-position: 0% 0;
  }
  to {
    -webkit-mask-position: 100% 0;
            mask-position: 100% 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -101%);
  }
}

@keyframes slide-down {
  0% {
    transform: translate(0, 101%);
  }
  100% {
    transform: translate(0, 0);
  }
}
