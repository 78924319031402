.wu-page_event {
  padding-top: 70px;
  padding-bottom: 165px;
}

.wu-event-content {
  .date {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: $brand-primary;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.45;
  }
}
