.wu-numbers {
  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: $brand-secondary;
  }

  h2 {
    color: #fff;
    text-align: center;
  }

  .numbers {
    display: flex;
    flex-wrap: wrap;
  }

  .number {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 65px 20px 55px;
    color: rgba(255, 255, 255, 0.6);
    font-family: $ff-body;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      width: 50%;
    }
    @include media-breakpoint-up(md) {
      width: 33.33333%;
    }
    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    strong {
      display: block;
      color: #fff;
      font-family: $ff-headings;
      font-size: 2.5rem;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 1.125;
      text-align: center;
      text-transform: none;
    }

    @include media-breakpoint-only(xs) {
      & + .number {
        border-top: 1px solid rgba(255, 255, 255, 0.25);
      }
    }

    @include media-breakpoint-only(sm) {
      &:nth-child(n + 3) {
        border-top: 1px solid rgba(255, 255, 255, 0.25);
      }

      &:nth-child(2n + 1) {
        border-left: 0;
      }
    }
    @include media-breakpoint-only(md) {
      &:nth-child(n + 4) {
        border-top: 1px solid rgba(255, 255, 255, 0.25);
      }

      &:nth-child(3n + 1) {
        border-left: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-child(n + 5) {
        border-top: 1px solid rgba(255, 255, 255, 0.25);
      }

      &:nth-child(4n + 1) {
        border-left: 0;
      }
    }
  }
}
