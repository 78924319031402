.wu-filters {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f3f3;

  form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: -10px;

    > div {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      margin: 10px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    label {
      display: block;
      margin: 0 0 5px;
      color: #000;
      font-family: $headings-font-family;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.1em;
      line-height: 1.175;
      text-transform: uppercase;
    }

    select {
      display: block;
      border: 0;
      border-bottom: 2px solid #000;
      border-radius: 0;
      padding: 5px 0;
      padding-right: 20px;
      width: 100%;
      height: 40px;
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 8px 10px;
      color: #000;
      font-family: $headings-font-family;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.45;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    [type="submit"] {
      margin: 0 !important;
      padding: 10px 20px !important;
    }
  }
}

.wu-filter {
  padding-top: 22px;
  padding-bottom: 20px;
  background-color: $brand-primary;
  border: 1px solid #979797;
  form {
    -ms-flex-pack: center !important;
    justify-content: center !important;
    .form-group {
      margin: 15px;
      @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;
        text-align: center;
        .form-control {
          width: 100%;
        }
      }
    }
    h2 {
      margin: 0;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.3em;
    }
    label {
      position: absolute;
      margin: -1px;
      padding: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
    select {
      display: inline-block;
      vertical-align: middle;
      border: 0;
      border-bottom: 2px solid #fff !important;
      border-radius: 0;
      padding: 3px 20px 3px 0;
      max-width: 100%;
      height: auto !important;
      background-color: transparent !important;
      background-image: url(../../../default/files/icons/icon-select.svg);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 8px 10px;
      color: #ffffff !important;
      font-family: $headings-font-family;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.3em;
      text-transform: uppercase;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
}
