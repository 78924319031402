.wu-contact_map {
	&:first-of-type {
		@include media-breakpoint-up(sm) {
			margin-top: -80px;
		}
		@include media-breakpoint-up(md) {
			margin-top: -100px;
		}
		@include media-breakpoint-down(xs) {
			margin-top: 60px;
		}
		.coord {
			@include media-breakpoint-up(sm) {
				color: #fff;
			}
			a:not([class*="btn"]) {
				@include media-breakpoint-up(sm) {
					color: #fff;
				}
			}
		}
	}
	.coord {
		margin-bottom: 30px;
		line-height: 1.5em;
		text-align: center;
		a:not([class*="btn"]) {
			text-decoration: none;
			@include transition(all .2s ease-out);
			&:hover {
				text-decoration: underline;
			}
		}
	}
	#map {
		width: 100%;
		height: 350px;

		a[href^="http://maps.google.com/maps"]{
			display:none !important;
		}
		a[href^="https://maps.google.com/maps"]{
			display:none !important;
		}

		.gmnoprint a, .gmnoprint span, .gm-style-cc {
		    display:none;
		}
		.gmnoprint div {
		    background:none !important;
		}
	}
}

.wu-contact_form {
	padding-top: 40px;
	padding-bottom: 95px;
	.intro {
		margin: 0 0 40px;
		text-align: center;
		&:after {
			content: "";
			display: block;
			margin: 15px auto 0;
			width: 55px;
			height: 2px;
			background-color: $brand-primary;
		}
		h2 {
			&:first-child {
				margin-bottom: 10px;
			}
		}
		.row {
			margin-bottom: 50px;
			&:last-child {
				margin-bottom: 0;
			}
			.col-md-6 {
				&:first-child {
					@include media-breakpoint-down(sm) {
						margin-bottom: 40px;
					}
				}
			}
		}
    .address {
      margin: 15px 0;
    }
	}
	form {
		margin-left: auto;
		margin-right: auto;
		max-width: 615px;
	}
}

.webform-submission-form {
  > .form-item {
    margin-bottom: 20px;
  }
}

