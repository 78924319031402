.wu-products {
	padding-top: 65px;
	padding-bottom: 30px;
	h2 {
		text-align: center;
	}
	.owl-carousel:not([class*="owl-loaded"]) {
		display: block;
		margin-left: -15px;
		margin-right: -15px;
		width: auto;
		font-size: 0;
		text-align: center;
		> .item-product_wrapper {
			display: inline-block;
			vertical-align: top;
			margin-left: 15px;
			margin-right: 15px;
			width: calc(25% - 30px);
			font-size: 1rem;
			text-align: left;
			@include media-breakpoint-down(lg) {
				width: calc(33.33333% - 30px);
			}
			@include media-breakpoint-down(md) {
				width: calc(50% - 30px);
			}
			@include media-breakpoint-down(xs) {
				width: calc(100% - 30px);
			}
		}
	}
	.owl-dots {
	    margin: 15px 0 0 !important;
	    font-size: 0;
	    text-align: center;
	    .owl-dot {
		    display: inline-block;
		    cursor: pointer;
		    span {
			    display: block;
			    margin: 0 15px;
			    border-radius: 50%;
		    	width: 10px;
			    height: 10px;
			    background: #000;
			    opacity: .3;
			    @include transition(all .2s ease-out);
		    }
		    &:hover {
		    	span {
		    		opacity: .9;
		    	}
		    }
		    &.active {
		    	span {
		    		background: $brand-primary;
		    		opacity: 1 !important;
		    	}
		    }
		}
	}
}

.wu-products_list {
  padding-top: 70px;
  padding-bottom: 60px;
  .item-product_wrapper {
    @include make-col-ready();
    @include make-col(4);
    @include media-breakpoint-down(md) {
      @include make-col(6);
    }
    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
  }
}
