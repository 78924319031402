.wu-cta {
	margin-bottom: 30px;
	padding-top: 40px;
	background-color: $brand-primary;
	color: #fff;
	text-align: center;

	h2,.h2,
	h3,.h3,
	h4,.h4,
	h5,.h5,
	h6,.h6 {
		margin: 0 0 15px;
		color: #fff;
	}

	.btn {
		margin-bottom: -30px;
		padding: 18px 50px;
	}
}
