// Button
// =============================================================================

.btn {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid transparent;
  border-radius: 4px;
  padding: 13px 35px;
  font-family: $headings-font-family;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25em;
  text-align: center;
  white-space: normal;
  cursor: pointer;
  outline: 0 !important;
  box-shadow: none !important;
  text-decoration: none !important;
  text-transform: uppercase;
  @include transition(all 0.5s ease-out);
  &:hover,
  &:focus {
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3) !important;
    -webkit-transform: translateY(-3px);
    -moztransform: translateY(-3px);
    transform: translateY(-3px);
  }
}
.btn-primary,
.btn1 {
  background-color: $brand-primary !important;
  border-color: $brand-primary !important;
  color: #fff !important;
}
.btn-secondary,
.btn2 {
  background-color: $brand-secondary !important;
  border-color: $brand-secondary !important;
  color: #fff !important;
}
.btn-lined-primary,
.btn3 {
  background-color: #fff !important;
  border-color: $brand-primary !important;
  color: $brand-primary !important;
}
.btn-lined-secondary,
.btn4 {
  background-color: #fff !important;
  border-color: $brand-secondary !important;
  color: $brand-secondary !important;
}
.btn-black {
  background-color: #1d1d1b !important;
  border-color: #1d1d1b !important;
  color: #fff !important;
}
.btn-white {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #000 !important;
}
.btn-lined-white {
  background-color: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}
.btn-link {
  border: 0;
  border-bottom: 1px solid $brand-primary;
  border-radius: 0;
  padding: 10px 0 9px;
  color: $brand-primary;

  &:hover,
  &:focus {
    border-color: transparent;
    box-shadow: none !important;
    -webkit-transform: none !important;
    -moztransform: none !important;
    transform: none !important;
  }
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
  @extend .btn;
  @extend .btn-primary;
}
