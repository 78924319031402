// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
.main-header {
  .top {
    color: #aeaeae;
    font-family: $headings-font-family;
    font-weight: 400;
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      left: 0;
      padding: 7px 15px;
      width: 100%;
      border-bottom: 1px solid #e5e5e5;
    }
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
  }

  .right {
    display: none;
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .socials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
    .social {
      font-size: 0.75rem;
      line-height: 1.25em;
      > a {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-decoration: none !important;
        @include media-breakpoint-down(lg) {
          padding: 16px;
          color: #fff;
        }
        span {
          display: none;
        }
        .fa {
          font-size: 1rem;
          line-height: 1;
          @include media-breakpoint-down(lg) {
            width: 16px;
            text-align: center;
          }
        }
        .icon {
          width: 1rem;
          height: 1rem;
        }

        &.facebook {
          color: #3b5998;
          @include media-breakpoint-down(lg) {
            background-color: #3b5998;
            color: #fff;
          }
        }
        &.linkedin {
          color: #0077b5;
          @include media-breakpoint-down(lg) {
            background-color: #0077b5;
            color: #fff;
          }
        }
        &.twitter {
          color: #1da1f2;
          @include media-breakpoint-down(lg) {
            background-color: #1da1f2;
            color: #fff;
          }
        }
        &.instagram {
          color: #303030;
          @include media-breakpoint-down(lg) {
            background-color: #303030;
            color: #fff;
          }
        }
        &.youtube {
          color: #ff0000;
          @include media-breakpoint-down(lg) {
            background-color: #ff0000;
            color: #fff;
          }
        }
      }

      & + .social {
        @include media-breakpoint-up(xl) {
          margin-left: 10px;
        }
      }
    }
  }

  .hours {
    display: flex;
    align-items: center;
    margin-left: 40px;
    color: #1d1d1b;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.45;

    @include media-breakpoint-down(lg) {
      margin-top: 40px;
      margin-left: 0;
      justify-content: center;
    }

    .fa {
      margin-right: 15px;
      color: $brand-primary;
    }
  }

  .top-menu {
    margin-left: 30px;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
      margin-left: 0;
      text-align: center;
    }
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-breakpoint-up(xl) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      > li {
        > a,
        > .nolink,
        > span:not([class*="expand"]) {
          display: block;
          padding: 6px 0;
          color: #1d1d1b;
          font-family: $headings-font-family;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.5;
          text-decoration: none;
          @include transition(all 0.2s);
          @include media-breakpoint-down(lg) {
            padding: 14px 15px;
            line-height: 1.675;
          }
        }

        & + li {
          @include media-breakpoint-up(xl) {
            margin-left: 20px;
          }
        }

        &:hover,
        &.active,
        &.active-trail {
          > a,
          > .nolink,
          > span:not([class*="expand"]) {
            color: $brand-primary;
          }
        }
      }
    }
  }

  .shop-customer-menu {
    margin-left: 30px;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
      margin-left: 0;
      text-align: center;
    }
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-breakpoint-up(xl) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      > li {
        position: relative;
        > a,
        > .nolink,
        > span:not([class*="expand"]) {
          display: block;
          padding: 6px 0;
          color: #1d1d1b;
          font-family: $headings-font-family;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.5;
          text-decoration: none;
          @include transition(all 0.2s);
          @include media-breakpoint-down(lg) {
            padding: 14px 15px;
            line-height: 1.675;
          }
        }

        & + li {
          @include media-breakpoint-up(xl) {
            margin-left: 20px;
          }
        }

        &:hover,
        &.active,
        &.active-trail {
          > a,
          > .nolink,
          > span:not([class*="expand"]) {
            color: $brand-primary;
          }
        }

        &.user-icon {
          > a,
          > .nolink,
          > span:not([class*="expand"]) {
            padding: 0;
            width: 30px;
            height: 30px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='448' height='512' viewBox='0 0 448 512'%3e%3cpath fill='%23fff' d='M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'/%3e%3c/svg%3e");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: auto 16px;
            font-size: 0;

            @include media-breakpoint-down(lg) {
              width: 100%;
              height: 40px;
            }
          }
        }

        @include media-breakpoint-up(xl) {
          &:hover {
            > ul {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        // Niv 2
        > ul {
          margin: 0;
          padding: 0;
          background-color: #eee;
          list-style: none;
          @include media-breakpoint-up(xl) {
            position: absolute;
            z-index: 1000;
            top: 100%;
            left: 0;
            border: 1px solid #e5e5e5;
            border-top: 2px solid $brand-primary;
            width: 210px;
            min-width: 100%;
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            @include transition(all 0.2s);
          }
          @include media-breakpoint-down(lg) {
            display: none;
          }
          > li {
            position: relative;

            > a,
            > .nolink,
            > span:not([class*="expand"]) {
              display: block;
              padding: 10px 15px;
              color: #1d1d1b;
              font-family: $headings-font-family;
              font-size: 0.75rem;
              font-weight: 400;
              line-height: 1.45em;
              text-decoration: none;
              @include transition(all 0.2s);
              @include media-breakpoint-down(lg) {
                padding: 15px;
              }
            }
            & + li {
              border-top: 1px solid #e5e5e5;
            }
            &:hover,
            &.active,
            &.active-trail {
              > a,
              > .nolink,
              > span:not([class*="expand"]) {
                color: $brand-primary;
              }
            }
          }
        }
      }
    }
  }

  .cta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 20px;
    font-size: 0.75rem;
    line-height: 1.25em;
    @include media-breakpoint-down(lg) {
      display: none;
      margin-top: 20px;
      margin-left: 0;
    }
    &.cta-mobile {
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
        margin-top: 0;
        margin-right: 10px;
      }
    }
    &:before {
      content: "|";
      padding-right: 14px;
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    > span {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    > a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-decoration: none !important;
      span {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .fa {
        font-size: 1rem;
        line-height: 1;
        @include media-breakpoint-down(lg) {
          width: 16px;
          text-align: center;
        }
      }
    }
    .btn {
      margin-left: 25px;
      padding: 6px 12px;
      font-size: 0.9rem;
      font-weight: 400;
      line-height: 1.35;
      @include media-breakpoint-down(lg) {
        margin-left: 0;
        border: 0;
        border-radius: 0;
        padding: 16px;
      }
      @include media-breakpoint-up(xl) {
        .fa {
          display: none;
        }
      }
    }
  }

  .lang {
    font-size: 0;
    position: relative;
    .block-language {
      position: relative;
      margin-left: 10px;
    }
    span {
      display: block;
      padding: 10px;
      font-size: 0.75rem;
      line-height: 1.25em;
      cursor: pointer;
      @include transition(all 0.2s);
      @include media-breakpoint-down(xs) {
        padding: 16px 13px 17px;
      }
      .fa {
        margin-left: 5px;
      }
      &:hover {
        color: $brand-primary;
      }
    }
    ul {
      display: none;
      position: absolute;
      z-index: 500;
      top: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      list-style: none;
      li {
        a {
          display: block;
          padding: 7px 15px;
          color: #1d1d1b;
          font-size: 0.75rem;
          line-height: 1.25em;
          text-decoration: none;
          @include transition(all 0.2s);
          @include media-breakpoint-down(xs) {
            padding: 16px 18px 17px;
          }
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}

.main-menu {
  @include media-breakpoint-up(xl) {
    position: static;
  }
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-right: 15px;
    }
    > li {
      position: relative;

      > a,
      > .nolink,
      > span:not([class*="expand"]) {
        display: block;
        padding: 38px 0 40px;
        border-top: 2px solid transparent;
        color: #1d1d1b;
        font-family: $headings-font-family;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25em;
        text-decoration: none;
        @include transition(all 0.2s);
        @include media-breakpoint-down(lg) {
          padding: 18px 15px 20px;
        }
      }

      & + li {
        @include media-breakpoint-up(xl) {
          margin-left: 40px;
        }
      }

      &:hover,
      &.active,
      &.active-trail {
        > a,
        > .nolink,
        > span:not([class*="expand"]) {
          color: $brand-primary;
        }
      }
      &.active,
      &.active-trail {
        > a,
        > .nolink,
        > span:not([class*="expand"]) {
          border-top-color: $brand-primary;
        }
      }

      &.button {
        @include media-breakpoint-down(lg) {
          padding-left: 15px;
          padding-right: 15px;
        }
        > a {
          @extend .btn;
          @extend .btn-primary;
          display: block !important;
          padding: 13px 15px !important;
          text-align: left !important;
          @include media-breakpoint-down(lg) {
            padding-top: 18px !important;
            padding-bottom: 18px !important;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      // Niv 2
      > ul {
        margin: 0;
        padding: 0;
        background-color: #eee;
        list-style: none;
        @include media-breakpoint-up(xl) {
          position: absolute;
          z-index: 1000;
          top: 100%;
          left: 0;
          border: 1px solid #e5e5e5;
          border-top: 2px solid $brand-primary;
          width: 210px;
          min-width: 100%;
          background-color: #fff;
          opacity: 0;
          visibility: hidden;
          @include transition(all 0.2s);
        }
        @include media-breakpoint-down(lg) {
          display: none;
        }
        > li {
          position: relative;

          > a,
          > .nolink,
          > span:not([class*="expand"]) {
            display: block;
            padding: 10px 15px;
            color: #1d1d1b;
            font-family: $headings-font-family;
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.45em;
            text-decoration: none;
            @include transition(all 0.2s);
            @include media-breakpoint-down(lg) {
              padding: 15px;
            }
          }
          & + li {
            border-top: 1px solid #e5e5e5;
          }
          &:hover,
          &.active,
          &.active-trail {
            > a,
            > .nolink,
            > span:not([class*="expand"]) {
              color: $brand-primary;
            }
          }

          // Niv 3
          > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            @include media-breakpoint-down(lg) {
              display: none;
            }
            > li {
              > a,
              > .nolink,
              > span:not([class*="expand"]) {
                display: block;
                padding: 10px 30px;
                color: #1d1d1b;
                font-family: $headings-font-family;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.25em;
                text-decoration: none;
                @include transition(all 0.2s);
                @include media-breakpoint-down(lg) {
                  padding: 15px;
                }
              }
              &:hover,
              &.active,
              &.active-trail {
                > a,
                > .nolink,
                > span:not([class*="expand"]) {
                  color: $brand-primary;
                }
              }
            }
          }
        }
      }

      &:last-child() {
        > ul {
          left: auto;
          right: 0;
        }
      }

      @include media-breakpoint-up(xl) {
        &.mm {
          position: static;
          > ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px 30px;
            position: absolute;
            left: 0 !important;
            right: 0 !important;
            padding: 30px 60px;
            width: 100%;
            > li {
              border-top: 0 !important;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    > .sublvl {
      li {
        > .expand {
          display: block;
          position: absolute;
          z-index: 5;
          top: 0;
          right: 0;
          width: 60px;
          height: 60px;
          background-color: #eee;
          &:before {
            content: "+";
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            width: 100%;
            color: #1d1d1b;
            font-size: 24px;
            line-height: 1em;
            text-align: center;
          }
        }
        &.open {
          > .expand {
            &:before {
              content: "-";
            }
          }
          > ul {
            display: block;
          }
        }
      }
      > li {
        > ul {
          > li {
            > .expand {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  nav#nav {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 90px 0 60px;
    visibility: hidden;
    opacity: 0;
    background-color: transparentize(#fff, 0.02);
    overflow-x: auto;
    overflow-y: scroll;
    -ms-transform: translate(100%, 0);
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    @include transition(all 0.35s);
  }
}

body.open-menu {
  @include media-breakpoint-down(lg) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}
