.item-event {
  @extend .item;
  height: 475px;
  .img-wrapper {
    position: relative;
    height: 237.5px;
    .date {
      position: absolute;
      top: 25px;
      right: 0;
      padding: 5px 10px;
      max-width: 75%;
      border-radius: 4px 0 0 4px;
      background-color: $brand-primary;
      color: #fff;
      font-size: 0.875rem;
      line-height: 1.45;
    }
  }
  .content {
    padding: 40px 10% 25px;
    height: 237.5px;
    font-size: 0.875rem;
    .inner {
      position: relative;
      height: 100%;
      overflow: hidden;
    }
  }
}
