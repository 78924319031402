.wu-highlight {
  padding-top: 75px;
  padding-bottom: 60px;
  .highlight {
    .content {
      position: relative;
      z-index: 10;
      .row {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        @include media-breakpoint-down(md) {
          div[class*="col-"] {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
      h2,.h2,
      h3,.h3,
      h4,.h4,
      h5,.h5,
      h6,.h6 {
        margin: 0 0 30px;
        color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
      }
      p, ul, ol {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a:not([class*="btn"]) {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      iframe {
        max-width: 100%;
      }
    }
    .text {
      color: #fff;
      font-size: 1rem;
      line-height: 1.5em;
    }
    &.primary {
      background-color: $brand-primary;
    }
    &.image {
      &:after {
        content: "";
        position: absolute;
        z-index: 5;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $brand-secondary;
        opacity: .55;
      }
    }
    &:only-child {
      position: relative;
      // max-width: 945px;
      .content {
        padding: 110px 8.75% 105px;
        h2,.h2,
        h3,.h3,
        h4,.h4,
        h5,.h5,
        h6,.h6 {
          line-height: 1.35em;
        }
        &.withBtn {
          font-size: 0;
          .text, .cta {
            display: inline-block;
            vertical-align: middle;
          }
          .text {
            padding-right: 15px;
            width: 62.5%;
            @include media-breakpoint-down(md) {
              padding-right: 0;
              width: 100%;
            }
          }
          .cta {
            padding-left: 15px;
            width: 37.5%;
            text-align: right;
            @include media-breakpoint-down(md) {
              margin-top: 30px;
              padding-left: 0;
              width: 100%;
              text-align: left;
            }
          }
        }
      }
      &.sm {
        .content {
          padding-top: 55px;
          padding-bottom: 50px;
        }
      }
      &.primary {
        .btn {
          @extend .btn-secondary;
        }
      }
    }
    &:not(:only-child) {
      .content {
        margin-left: auto;
        margin-right: auto;
        padding: 60px 15px 75px;
        max-width: 400px;
      }
      .cta {
        margin-top: 15px;
      }
      h2,.h2,
      h3,.h3,
      h4,.h4,
      h5,.h5,
      h6,.h6 {
        line-height: 1em;
      }
      .btn {
        background-color: transparent !important;
        border-color: #fff !important;
        color: #fff !important;
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(even) {
          background-color: $brand-secondary;
        }
      }
      @include media-breakpoint-between(md,md) {
        &:nth-child(4n+2) {
          background-color: $brand-secondary;
        }
        &:nth-child(4n+3) {
          background-color: $brand-secondary;
        }
      }
      @include media-breakpoint-down(sm) {
        &:nth-child(even) {
          background-color: $brand-secondary;
        }
      }
    }
  }
}
