// Block
.block--rentiteasy {
  padding-top: 40px;
  padding-bottom: 60px;

  .inner {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 260px 1fr;
    }
  }

  .filters {
    padding-top: 5px;
    padding-bottom: 5px;

    .filter {
      margin-bottom: 40px;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin: 0;
        color: #000;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.35;

        @include media-breakpoint-down(sm) {
          padding-left: 30px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23000' d='M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z'/%3e%3c/svg%3e");
          background-position: left center;
          background-repeat: no-repeat;
          background-size: 16px auto;
          cursor: pointer;
        }
      }

      .filter-rentiteasy_wrapper {
        @include media-breakpoint-down(sm) {
          max-height: 0;
          overflow: hidden;
          transition: all 0.2s ease-in;
        }

        @include media-breakpoint-up(md) {
          max-height: none !important;
        }
      }
      .filter-rentiteasy_inner {
        padding-top: 40px;
      }

      ul {
        padding-left: 0;
        list-style-type: none;

        > li {
          > a {
            display: block;
            color: #000;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.25;
            text-decoration: none;
            transition: all 0.2s ease-in;

            &:hover {
              color: $brand-primary;
            }

            &:not([data-tid="all"]) {
              position: relative;
              padding-left: 30px;

              &::before,
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 50%;
                width: 20px;
                height: 20px;
              }

              &::before {
                z-index: 1;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 9px 6px;
              }

              &::after {
                border: 1px solid #e1e1e1;
                background-color: #fff;
              }

              &.active {
                &::before {
                  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6'%3e%3cpath fill='%23fff' d='M8.85.15a.48.48,0,0,0-.7,0L3.5,4.79.85,2.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7l3,3a.48.48,0,0,0,.7,0l5-5A.48.48,0,0,0,8.85.15Z'/%3e%3c/svg%3e");
                }

                &::after {
                  border-color: #000;
                  background-color: #000;
                }
              }
            }

            &[data-tid="all"] {
              font-weight: 700;
            }
          }

          & + li {
            margin-top: 20px;
          }
        }
      }

      .form-check {
        &.hide {
          display: none;
        }

        > label {
          display: block;
          position: relative;
          margin-bottom: 0;
          padding-left: 30px;
          color: #000;
          font-family: $ff-body;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.25;
          text-decoration: none;
          text-transform: none;
          transition: all 0.2s ease-in;
          cursor: pointer;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            width: 20px;
            height: 20px;
          }

          &::before {
            z-index: 1;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 9px 6px;
          }

          &::after {
            border: 1px solid #e1e1e1;
            background-color: #fff;
          }

          &:hover {
            color: $brand-primary;
          }
        }

        > input {
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;

          &:checked {
            ~ label {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9 6'%3e%3cpath fill='%23fff' d='M8.85.15a.48.48,0,0,0-.7,0L3.5,4.79.85,2.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7l3,3a.48.48,0,0,0,.7,0l5-5A.48.48,0,0,0,8.85.15Z'/%3e%3c/svg%3e");
              }

              &::after {
                border-color: #000;
                background-color: #000;
              }
            }
          }
        }

        & + .form-check {
          margin-top: 20px;
        }

        &.all {
          > label {
            padding-left: 0;
            font-weight: 700;

            &::before,
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .results-search {
    margin-bottom: 20px;

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      margin: -8px;

      label {
        display: block;
        margin: 8px;
        color: #848484;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;
        text-transform: none;
      }

      input {
        display: block;
        margin: 8px;
        border: 0;
        border-radius: 0;
        padding: 5px 15px;
        padding-right: 45px;
        width: auto;
        height: 40px;
        background-color: #f0f0f0;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="%23000" d="M15.76,14.57l-3.95-4A6.3,6.3,0,0,0,11,1.51a6.87,6.87,0,0,0-9.43.8,6.31,6.31,0,0,0,.83,9.1,6.87,6.87,0,0,0,8.13.34l4,4a.89.89,0,0,0,1.24,0A.82.82,0,0,0,15.76,14.57ZM6.69,1.68a4.86,4.86,0,0,1,4.94,4.78,4.86,4.86,0,0,1-4.94,4.77A4.87,4.87,0,0,1,1.74,6.46h0A4.87,4.87,0,0,1,6.69,1.68Z"/%3E%3C/svg%3E');
        background-position: right 15px center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3;
        appearance: none;

        &::placeholder {
          color: #000;
        }
      }
    }
  }

  .results-list {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// Item
.item--rentiteasy {
  display: block;
  text-decoration: none;

  &.hide {
    display: none;
  }

  .img-wrapper {
    position: relative;
    height: 180px;
    background-color: #f0f0f0;

    &::after {
      content: attr(data-hover);
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      margin-top: 20px;
      padding: 5px 20px;
      background-color: #fff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: 20px 0 0;
    color: #444;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;

    h3 {
      margin: 0 0 10px;
      color: #000;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25;
    }

    .price {
      margin: 0 0 10px;
      color: $brand-primary;
      font-weight: 700;
    }
  }

  &:hover {
    .img-wrapper {
      &::after {
        margin-top: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// Details
.block--rentiteasy_back {
  padding-top: 20px;

  .back-link {
    .fa {
      margin-right: 5px;
    }
  }
}

.block--rentiteasy_details {
  padding-bottom: 60px;

  .inner {
    display: grid;
    gap: 40px;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    .visuals {
      padding-top: 20px;
      padding-bottom: 20px;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
      }

      .highlight {
        position: relative;
        padding: 25px;
        background-color: #f0f0f0;
        height: 330px;

        @include media-breakpoint-up(sm) {
          flex: 1 0 0%;
          width: 100%;
          max-width: 100%;
          order: 1;
          height: 500px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
          object-fit: contain;
        }
      }

      .thumbs {
        @include media-breakpoint-down(xs) {
          display: flex;
          flex-wrap: wrap;
          margin: -5px;
          padding-top: 10px;
        }
        @include media-breakpoint-up(sm) {
          flex: 0 0 auto;
          width: 100px;
          max-width: 100%;
          padding-right: 10px;
        }

        .thumb {
          height: 100px;
          background-color: #f0f0f0;

          @include media-breakpoint-down(xs) {
            margin: 5px;
            width: 90px;
          }

          & + .thumb {
            @include media-breakpoint-up(sm) {
              margin-top: 10px;
            }
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      color: #808080;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;

      @include media-breakpoint-up(xl) {
        padding-left: 60px;
      }

      h1 {
        margin-bottom: 10px;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.35;
      }

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .price {
        margin-bottom: 25px;
        color: $brand-primary;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.25;
        text-transform: uppercase;
      }
    }

    .details {
      color: #000;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.725;

      @include media-breakpoint-up(lg) {
        grid-column-start: 1;
        grid-column-end: span 2;
      }

      .title {
        margin: 30px 0 20px;
        color: #000;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.25;
      }
    }
  }
}

.block--rentiteasy_form {
  padding-top: 60px;
  padding-bottom: 60px;

  form {
    display: grid;
    gap: 20px 40px;

    > div {
      margin: 0;
    }

    > input[type="submit"] {
      margin-right: auto;
    }
  }
}
