.block--reviews {
  padding-top: 60px;
  padding-bottom: 60px;

  #map-plug {
    display: none;
  }

  #google-reviews {
    display: flex;
    flex-wrap: wrap;

    .review-item,
    .review-item-long {
      flex: 1 1 20%;
      margin: 0 auto;
      // border: 1px solid rgba(190, 190, 190, 0.35);
      padding: 1em;

      @include media-breakpoint-down(lg) {
        flex: 1 1 40%;
      }

      @include media-breakpoint-down(xs) {
        flex: 1 1 90%;
      }

      .review-picture {
        display: block;
        margin: 0 auto 20px;
        max-width: 100%;
        width: 100px;
        height: auto;
      }

      .review-meta,
      .review-stars {
        text-align: center;
        // font-size: 115%;
      }

      .review-author {
        font-weight: 700;
        text-transform: capitalize;
      }
      .review-date {
        display: block;
        opacity: 0.6;
      }

      .review-stars {
        ul {
          display: inline-block;
          margin: 0;
          padding: 0;
          list-style: none !important;

          li {
            float: left;
            margin-right: 1px;
            line-height: 1;
            list-style: none !important;

            i {
              color: #e4b248;
              font-size: 1.4em;
              font-style: normal;

              &.star {
                &::after {
                  content: "\2605";
                }
              }

              &.inactive {
                color: #c6c6c6;
              }
            }
          }
        }
      }

      .review-text {
        margin: auto;
        max-width: 32em;
        line-height: 1.55;
        text-align: left;
      }
    }
  }
}
