// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
  padding-top: 150px;
  @include media-breakpoint-down(lg) {
    padding-top: 100px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }
}
.container {
  &.md,
  &.lg {
    max-width: 100%;
  }
  &.md {
    width: 975px;
  }
  &.lg {
    width: 1320px;
  }
}

.no-marge {
  margin-left: 0;
  margin-right: 0;
}
.no-gap {
  padding-left: 0;
  padding-right: 0;
}

.wu-main_content {
  padding-top: 60px;
  padding-bottom: 110px !important;
  .row {
    margin-top: 65px;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    div[class*="col-"] {
      &:first-child {
        padding-right: 50px;
        @include media-breakpoint-down(md) {
          padding-right: 15px;
        }
      }
      &:nth-child(2) {
        padding-left: 50px;
        @include media-breakpoint-down(md) {
          padding-left: 15px;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      div[class*="col-"] {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .text-center {
    margin-left: auto;
    margin-right: auto;
    max-width: 635px;
    &.lead {
      max-width: 870px;
    }
  }
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    max-width: 790px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 30px;
      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.cta-fixed {
  display: block;
  position: fixed;
  z-index: 900;
  top: 160px;
  right: 0;
  background-color: #1d1d1b;
  border-radius: 4px 0 0 4px;
  color: #fff !important;
  font-size: 0;
  outline: 0 !important;
  text-decoration: none !important;
  @include transition(all 0.5s);
  @include media-breakpoint-up(lg) {
    top: 240px;
  }
  .fa,
  span {
    display: inline-block;
    vertical-align: middle;
  }
  .fa {
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
  }
  span {
    padding: 10px 15px;
    max-width: 300px;
    font-size: 1rem;
    line-height: 1.5em;
    white-space: nowrap;
    @include transition(all 0.2s);
    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: 0;
    }
  }
  &.scrolled {
    span {
      padding: 0;
      max-width: 0;
    }
  }
  &:hover {
    span {
      padding: 10px 15px;
      max-width: 300px;
    }
  }
}

.back-to-top {
  position: fixed;
  z-index: 900;
  right: 15px;
  bottom: 15px;
  display: block;
  width: 48px;
  height: 48px;
  background-color: #2d2d2d;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  line-height: 48px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  @include transition(all 0.2s);
  &.scrolled {
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    background-color: $brand-primary;
    color: #fff;
  }
}

.os-animation {
  opacity: 0;
}

.os-animation.animated {
  opacity: 1;
}

.infowindow-content {
  h2 {
    margin: 0;
  }
  p {
    margin: 0 0 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-backdrop {
  background-color: #2f2f2f;
  &.show {
    opacity: 0.75;
  }
}

#tabs_tasks {
  position: fixed;
  z-index: 1500;
  bottom: 0;
  left: 0;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    li {
      display: inline-block;
      vertical-align: middle;
      & + li {
        margin-left: 1px;
      }
      a {
        display: block;
        padding: 15px 20px;
        background-color: #1d1d1b;
        color: #fff;
        font-size: 0.9rem;
        line-height: 1.45em;
        text-decoration: none;
        @include transition(all 0.35s);
      }
      &:hover {
        a {
          background-color: $brand-primary;
        }
      }
    }
  }
}

.webform-confirmation {
  @extend .container;
  padding-top: 60px;
  padding-bottom: 110px;
}

.shareBox {
  position: relative;
  float: right;
  .share {
    display: block;
    padding: 20px 0;
    color: $brand-primary;
    font-size: 1.13rem;
    font-weight: 700;
    line-height: 1.11em;
    text-decoration: none;
    @include transition(0.2s all);
    .fa {
      margin-right: 10px;
    }
    &:hover,
    &.active {
      color: $brand-primary;
    }
  }
  .addthis_toolbox {
    display: none;
    position: absolute;
    z-index: 10;
    top: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
    white-space: nowrap;
    li {
      display: inline-block;
      a {
        display: block;
        margin: 0;
        padding: 0;
        height: 48px;
        width: 48px;
        background-color: $brand-primary;
        color: #fff;
        font-size: 1.13rem;
        line-height: 48px;
        text-align: center;
        text-decoration: none !important;
        @include transition(0.2s all);
        span {
          display: none;
        }
        &:hover {
          background-color: $brand-primary;
          color: #fff;
        }
      }
    }
  }
}
