.pager {
  margin-top: 2.5rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -0.3125rem;
    padding-left: 0;
    list-style: none;

    li {
      a,
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.3125rem;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.4286;
        text-decoration: none;
      }

      &.pager__item--first,
      &.pager__item--previous,
      &.pager__item--next,
      &.pager__item--last {
        a,
        span {
          width: 2.5rem;
          height: 2.5rem;
          background-color: $brand-primary;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 0.875rem 0.875rem;
          color: #fff;

          span:not(.visually-hidden) {
            display: none;
          }
        }
      }
      &.pager__item--first {
        a,
        span {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffffff' d='M2.71,8L8.35,2.35h0c.2-.2,.19-.51,0-.71h0c-.2-.2-.51-.2-.71,0L1.65,7.65h0c-.2,.2-.19,.51,0,.71l6,6c.2,.2,.51,.2,.71,0s.2-.51,0-.71L2.71,8Zm4,0L12.35,2.35h0c.2-.2,.19-.51,0-.71h0c-.2-.2-.51-.2-.71,0L5.65,7.65h0c-.2,.2-.19,.51,0,.71l6,6c.2,.2,.51,.2,.71,0s.2-.51,0-.71l-5.65-5.65Z'/%3e%3c/svg%3e");
        }
      }
      &.pager__item--previous {
        a,
        span {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffffff' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
        }
      }
      &.pager__item--next {
        a,
        span {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffffff' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }
      &.pager__item--last {
        a,
        span {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23ffffff' d='M10.35,8.35c.2-.2,.19-.51,0-.71L4.35,1.65h0c-.2-.2-.51-.2-.71,0h0c-.2,.2-.19,.51,0,.71l5.65,5.65L3.65,13.65c-.2,.2-.2,.51,0,.71s.51,.2,.71,0l6-6h0Zm4-.71L8.35,1.65h0c-.2-.2-.51-.2-.71,0h0c-.2,.2-.19,.51,0,.71l5.65,5.65-5.65,5.65c-.2,.2-.2,.51,0,.71s.51,.2,.71,0l6-6h0c.2-.2,.19-.51,0-.71Z'/%3e%3c/svg%3e");
        }
      }
    }
  }
}
