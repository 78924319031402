.item-service {
	display: block;
	margin: 15px 0;
	text-align: center;
	text-decoration: none !important;
	.img-wrapper {
		position: relative;
		margin: 0 auto;
		max-width: 360px;
		height: 130px;
		background-color: #000;
		overflow: hidden;
		.bg {
			@include transition(all .35s ease-out);
		}
	}
	.icon-wrapper {
		padding: 25px 0;
		.icon {
			display: block;
			margin: 0 auto;
			border-radius: 50%;
			width: 80px;
			height: 80px;
			background-color: $brand-primary;
			text-align: center;
			.fa {
				color: #fff;
				font-size: 1.75rem;
				line-height: 80px;
				@include transition(all .35s ease-out);
			}
		}
	}
	.content {
		margin: 0 auto;
		padding: 15px;
		max-width: 290px;
		color: $body-color;
		line-height: 1.5em;
		h3, p {
			margin: 0;
		}
		h3 {
			@include transition(all .35s ease-out);
			& + .link {
				margin-top: 15px;
			}
		}
	}
}
a.item-service:not([class*="disabled"]):hover {
	.img-wrapper {
		.bg {
			-ms-transform: scale(1.1);
			-webkit-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
	.icon-wrapper {
		.icon {
			.fa {
				-ms-transform: scale(1.25) rotate(15deg);
				-webkit-transform: scale(1.25) rotate(15deg);
				transform: scale(1.25) rotate(15deg);
			}
		}
	}
	.content {
		h3 {
			color: $brand-primary;
		}
		.link {
			text-decoration: none;
		}
	}
}