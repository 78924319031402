.path-rendez-vous {
  .appointment {
    padding-top: 15px;
    padding-bottom: 220px;
    color: #898989;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;

    strong {
      color: #000;
      font-weight: 700;
    }

    .intro,
    .confirmation {
      margin: 0 auto 30px;
      max-width: 500px;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .title {
      margin: 0 0 20px;
      color: #000;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.225;
    }

    .navigation {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      margin-left: -5px;
      margin-right: -5px;

      > * {
        flex: 0 0 auto;
        width: calc(50% - 10px);
        max-width: 100%;
        display: block;
        margin: 5px;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 10px 20px;
        background-color: #fff;
        background-repeat: no-repeat;
        color: #000;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.3;
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-in;
        cursor: pointer;

        @include media-breakpoint-up(sm) {
          width: auto;
        }

        &.disabled {
          border-color: #888;
          color: #898989;
          pointer-events: none;
          cursor: default;
        }

        &.prev {
          padding-left: 30px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3e%3cpath fill='%23000000' d='M0,5,6,0V10Z'/%3e%3c/svg%3e");
          background-position: left 10px center;
          background-size: 6px auto;

          @include media-breakpoint-up(sm) {
            order: -1;
          }

          &:not([class*="disable"]):hover {
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
            transform: translateY(-3px);
          }

          &.disabled {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3e%3cpath fill='%23898989' d='M0,5,6,0V10Z'/%3e%3c/svg%3e");
          }
        }

        &.next {
          padding-right: 30px;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3e%3cpath fill='%23000000' d='M6,5,0,10V0Z'/%3e%3c/svg%3e");
          background-position: right 10px center;
          background-size: 6px auto;

          &:not([class*="disable"]):hover {
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
            transform: translateY(-3px);
          }

          &.disabled {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3e%3cpath fill='%23898989' d='M6,5,0,10V0Z'/%3e%3c/svg%3e");
          }
        }

        &.search-date,
        &.select-date {
          padding-left: 45px;
          width: calc(100% - 10px);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3e%3cpath fill='%23000000' d='M14,7.5a.5.5,0,0,0-1,0,.5.5,0,0,0,1,0Zm1.19-6.09h-.92V.7a.7.7,0,0,0-1.4,0v.7H9.67V.7A.71.71,0,0,0,8.26.7v.7H5.1V.7A.71.71,0,0,0,3.69.7v.7H2.81A2.83,2.83,0,0,0,0,4.22v11A2.81,2.81,0,0,0,2.81,18H8.19a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.71H2.81a1.4,1.4,0,0,1-1.4-1.4v-11a1.4,1.4,0,0,1,1.4-1.4h.88v.7a.71.71,0,0,0,1.41,0v-.7H8.26v.7a.71.71,0,0,0,1.41,0v-.7h3.2v.7a.7.7,0,0,0,1.4,0v-.7h.92a1.4,1.4,0,0,1,1.4,1.4v4a.7.7,0,0,0,.71.7.7.7,0,0,0,.7-.7v-4A2.81,2.81,0,0,0,15.19,1.41ZM13.75,9.49A4.26,4.26,0,1,0,18,13.75,4.26,4.26,0,0,0,13.75,9.49Zm0,7.1a2.85,2.85,0,1,1,2.84-2.84A2.84,2.84,0,0,1,13.75,16.59Zm1-3.55h-.31V12.3a.7.7,0,0,0-.7-.7.71.71,0,0,0-.71.7v1.44a.7.7,0,0,0,.7.7h1a.7.7,0,1,0,0-1.4ZM11,7.5a.5.5,0,0,0-1,0,.5.5,0,0,0,1,0ZM7.5,10a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,10Zm-3-3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,7Zm0,3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,10Zm0,3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,13Zm3,0a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,13Zm0-6a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,7Z'/%3e%3c/svg%3e");
          background-position: left 15px center;
          background-size: 18px auto;

          @include media-breakpoint-up(sm) {
            width: auto;
          }

          &::placeholder {
            color: inherit;
          }

          &.disabled {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3e%3cpath fill='%23898989' d='M14,7.5a.5.5,0,0,0-1,0,.5.5,0,0,0,1,0Zm1.19-6.09h-.92V.7a.7.7,0,0,0-1.4,0v.7H9.67V.7A.71.71,0,0,0,8.26.7v.7H5.1V.7A.71.71,0,0,0,3.69.7v.7H2.81A2.83,2.83,0,0,0,0,4.22v11A2.81,2.81,0,0,0,2.81,18H8.19a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.71H2.81a1.4,1.4,0,0,1-1.4-1.4v-11a1.4,1.4,0,0,1,1.4-1.4h.88v.7a.71.71,0,0,0,1.41,0v-.7H8.26v.7a.71.71,0,0,0,1.41,0v-.7h3.2v.7a.7.7,0,0,0,1.4,0v-.7h.92a1.4,1.4,0,0,1,1.4,1.4v4a.7.7,0,0,0,.71.7.7.7,0,0,0,.7-.7v-4A2.81,2.81,0,0,0,15.19,1.41ZM13.75,9.49A4.26,4.26,0,1,0,18,13.75,4.26,4.26,0,0,0,13.75,9.49Zm0,7.1a2.85,2.85,0,1,1,2.84-2.84A2.84,2.84,0,0,1,13.75,16.59Zm1-3.55h-.31V12.3a.7.7,0,0,0-.7-.7.71.71,0,0,0-.71.7v1.44a.7.7,0,0,0,.7.7h1a.7.7,0,1,0,0-1.4ZM11,7.5a.5.5,0,0,0-1,0,.5.5,0,0,0,1,0ZM7.5,10a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,10Zm-3-3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,7Zm0,3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,10Zm0,3a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,4.5,13Zm3,0a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,13Zm0-6a.5.5,0,0,0-.5.5.5.5,0,0,0,1,0A.5.5,0,0,0,7.5,7Z'/%3e%3c/svg%3e");
          }
        }

        &.select-date {
          appearance: none;
        }
      }
    }

    .days {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -15px;
        margin-right: -15px;
      }

      .day {
        margin-top: 15px;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
          flex: 0 0 auto;
          width: 100%;
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          width: calc(100% / 7);
          text-align: center;
        }

        &.open {
          @include media-breakpoint-down(md) {
            h3 {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3e%3cpath fill='%23000000' d='M136.5 185.1l116 117.8c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L128 224.7 27.6 326.9c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l116-117.8c4.7-4.6 12.3-4.6 17 .1z'/%3e%3c/svg%3e");
            }
          }
        }

        h3 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          border-radius: 4px;
          padding: 10px 20px;
          padding-right: 50px;
          background-color: #ebebeb;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3e%3cpath fill='%23000000' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e");
          background-position: right 20px center;
          background-repeat: no-repeat;
          background-size: 10px auto;
          color: #000;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.3;
          transition: all 0.2s ease-in;
          cursor: pointer;

          @include media-breakpoint-up(md) {
            display: block;
            margin-bottom: 15px;
            border-radius: 0;
            border-bottom: 1px solid #b2b2b2;
            padding: 0;
            padding-bottom: 10px;
            background-color: transparent;
            background-image: none;
            cursor: default;
          }

          span {
            display: block;
            color: #797979;
            font-weight: 400;

            @include media-breakpoint-up(md) {
              margin-top: 5px;
            }
          }
        }

        .list-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.2s ease-in;

          @include media-breakpoint-up(md) {
            max-height: none !important;
            overflow: visible;
          }
        }

        ul {
          margin: 0;
          padding-top: 10px;
          padding-left: 0;
          list-style-type: none;

          @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-left: 15px;
            padding-right: 15px;
          }

          li {
            > * {
              display: flex;
              align-items: center;
              padding: 10px 20px;
              width: 100%;
              height: 40px;
              color: #000;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.3;
              text-decoration: none;
              transition: all 0.2s ease-in;
              cursor: pointer;

              @include media-breakpoint-up(md) {
                justify-content: center;
                border: 1px solid #ebebeb;
                border-radius: 4px;
                padding: 0;
                background-color: #ebebeb;
              }
            }

            &:not([class*="disabled"]):hover {
              > * {
                color: $brand-primary;

                @include media-breakpoint-up(md) {
                  border-color: $brand-primary;
                  background-color: $brand-primary;
                  color: #fff;
                }
              }
            }

            &.disabled {
              display: none;

              @include media-breakpoint-up(md) {
                display: block;
              }

              > * {
                color: #aaa;
                pointer-events: none;
                cursor: default;

                @include media-breakpoint-up(md) {
                  border-color: #ccc;
                  background-color: transparent;
                }
              }
            }

            & + li {
              @include media-breakpoint-up(md) {
                margin-top: 5px;
              }
            }
          }
        }

        .empty {
          padding: 0 20px;
          color: #aaa;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.3;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }

    .confirmation {
      .title {
        margin-bottom: 35px;
        padding-top: 75px;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3e%3cpath fill='%23000000' d='M35.71,4.29H31.42V1.43a1.43,1.43,0,1,0-2.86,0V4.29H11.43V1.43a1.43,1.43,0,0,0-2.86,0V4.29H4.29A4.29,4.29,0,0,0,0,8.57V35.71A4.29,4.29,0,0,0,4.29,40H35.71A4.29,4.29,0,0,0,40,35.71V8.57A4.29,4.29,0,0,0,35.71,4.29Zm0,32.86H4.29a1.44,1.44,0,0,1-1.43-1.43V17.14H37.14V35.72A1.44,1.44,0,0,1,35.71,37.15Zm1.45-22.87H2.86V8.57A1.43,1.43,0,0,1,4.29,7.14H8.58V10a1.43,1.43,0,0,0,2.86,0V7.14H28.58V10a1.43,1.43,0,1,0,2.86,0V7.14h4.29a1.43,1.43,0,0,1,1.43,1.43Zm-7.62,6.14a1.41,1.41,0,0,0-2,0L17.14,30.84l-4.7-4.7a1.43,1.43,0,0,0-2,2l5.71,5.71a1.42,1.42,0,0,0,2,0L29.58,22.44a1.43,1.43,0,0,0,0-2Z'/%3e%3c/svg%3e");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 40px auto;
      }
    }
  }

  #frontform {
    padding-top: 15px;
    padding-bottom: 175px;
    padding-left: 15px;
    padding-right: 15px;

    .form-item {
      margin-left: auto;
      margin-right: auto;
      max-width: 360px;

      & + .form-item {
        margin-top: 10px;
      }
    }

    input[type="submit"] {
      display: block;
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
