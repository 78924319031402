body.path-user, body.error-404, body.error-401, body.error-403,  {
  .main-wrapper {
    @extend .container;
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

body.path-user {
  .main-wrapper {
    .form-item {
      margin-bottom: 20px;
    }
  }
}
