// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  @include transition(all .35s);

  .container {
    padding-top: 50px;
    @include media-breakpoint-down(lg) {
      padding-top: 0;
    }
  }

  .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    margin-left: -15px;
    padding: 5px 15px;
    @include media-breakpoint-down(sm) {
      max-width: 180px;
    }
    img {
      max-width: 100%;
      max-height: 90px;
      height: auto;
      @include media-breakpoint-down(md) {
        max-height: 60px;
       }
    }
  }

  .toggle-menu {
      display: none;
      position: relative;
      z-index: 5;
      padding: 12px 4px;
      width: 40px;
      height: 24px;
      cursor: pointer;
      box-sizing: content-box;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      span {
        display: block;
        position: absolute;
        border-radius: 4px;
        width: 40px;
        height: 4px;
        background-color: #1D1D1B;
        transition: background-color .075s ease;
        &:nth-child(1) {
          top: 12px;
          transition: top .075s ease .12s, transform .075s cubic-bezier(.55,.055,.675,.19);
        }
        &:nth-child(2) {
          top: 50%;
          margin-top: -2px;
          transition: opacity .075s ease;
        }
        &:nth-child(3) {
          bottom: 12px;
          transition: bottom .075s ease .12s, transform .075s cubic-bezier(.55,.055,.675,.19);
        }
      }

    &.open {
      span {
          &:nth-child(1) {
            top: 22px;
            transform: rotate(45deg);
            transition: top .075s ease, transform .075s cubic-bezier(.215,.61,.355,1) .12s;
          }
          &:nth-child(2) {
            opacity: 0;
            transition: opacity .075s ease .12s;
          }
          &:nth-child(3) {
            bottom: 22px;
            transition: bottom .075s ease, transform .075s cubic-bezier(.215,.61,.355,1) .12s;
            transform: rotate(-45deg);
          }
      }
    }
  }

  &.scrolled {
    top: -50px;
    border-bottom: 1px solid #E5E5E5;
    @include media-breakpoint-down(lg) {
        top: 0;
    }
  }

  &.scrollUp {
    top: 0;
  }
}
