.wu-team {
  padding-top: 60px;
  padding-bottom: 60px;
  h2 {
    text-align: center;
  }
  .member {
    color: #646464;
    line-height: 1.5em;
    text-align: center;
    .img-wrapper {
      margin: 0 0 30px;
      border-radius: 50%;
      overflow: hidden;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    h3 {
      margin: 0;
      color: #2B2B2B;
      font-size: 1rem;
      line-height: 1.5em;
    }
    .function {
      margin: 0;
      color: $body-color;
      font-size: 0.9rem;
      font-style: italic;
      line-height: 1.7em;
    }
  }
}
