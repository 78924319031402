body.cke_editable {
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;

  img {
    max-width: 100%;
    height: auto;
  }

  .basewidget-menu-wrapper {
    .btn {
      margin: 2px;
      border: 1px solid $brand-primary;
      border-radius: 4px;
      padding: 2px 7px;
      background-color: #fff;
      color: $brand-primary;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.25em;
      text-transform: none;
    }
  }
}
