.item {
	display: block;
	position: relative;
	margin: 15px 0;
	border: 1px solid #E5E5EE;
	border-radius: 4px;
	height: 450px;
	background-color: #fff;
	text-decoration: none !important;
	overflow: hidden;
	@include transition(all .35s ease-out);
	.logo-wrapper {
		position: relative;
	    padding: 30px;
	    height: 200px;
	    border-bottom: 1px solid #E5E5EE;
		.inner {
			position: relative;
			height: 100%;
			overflow: hidden;
			img {
				position: absolute;
			    top: 50%;
			    left: 50%;
				height: auto;
			    max-height: 100%;
			    width: auto;
			    max-width: 100%;
			    -ms-transform: translate(-50%,-50%);
			    -webkit-transform: translate(-50%,-50%);
			    transform: translate(-50%,-50%);
			    @include transition(all .35s ease-out);
			}
		}
	}
	.img-wrapper {
		position: relative;
		height: 200px;
		background-color: #000;
		overflow: hidden;
		.bg {
			@include transition(all .35s ease-out);
		}
	}
	.content {
		padding: 30px 10%;
		color: #646464;
		line-height: 1.5em;
		h3, p {
			margin: 0;
		}
		h3 {
			color: #2B2B2B;
			font-size: 1rem;
			line-height: 1.5em;
			@include transition(all .35s ease-out);
		}
		.date, .place {
			color: $body-color;
			font-size: 0.9rem;
			line-height: 1.7em;
		}
		.link {
			@include transition(all .35s ease-out);
		}
	}
	&:not([class*="disabled"]):hover {
		.logo-wrapper {
			img {
				-ms-transform: translate(-50%,-50%) scale(1.1);
			    -webkit-transform: translate(-50%,-50%) scale(1.1);
			    transform: translate(-50%,-50%) scale(1.1);
			}
		}
		.img-wrapper {
			.bg {
				-ms-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.content {
			h3 {
				color: $brand-primary;
			}
			.link {
				text-decoration: none;
			}
		}
	}
}