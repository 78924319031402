.wu-page_news {
	padding-top: 70px;
	padding-bottom: 165px;
	.item-news {
		&.featured {
			.content {
				@include media-breakpoint-up(sm) {
					padding-top: 100px;
					padding-bottom: 100px;
				}
			}
		}
	}
  .item-news_wrapper {
    &:first-child {
      @include make-col(8);
      @include media-breakpoint-down(md) {
        @include make-col(12);
      }
      .item-news {
        @extend .featured;
      }
    }
  }
}
